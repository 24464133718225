import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';

import { SystemAdminAuthGuard } from '../../../utils/guards/systemadmin-auth.guard';


import { AlertMessageSystemAdminListComponent } from './alertmessagesystemadminlist.component';

const routes: Routes = [
    {
        path: '',
        component: AlertMessageSystemAdminListComponent,
        canActivate: [SystemAdminAuthGuard],
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlertMessageSystemAdminListRoutingModule { }
