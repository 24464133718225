import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthService } from '../utils/services/auth.service';
import { MustMatch } from '../utils/must-match.validator';

@Component({
  selector: 'app-completenewpassword',
  templateUrl: './completenewpassword.component.html',
  styleUrls: ['./completenewpassword.component.scss']
})
export class CompleteNewPasswordComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  isValidFormSubmitted = false;
  private email: String;
  private oldPassword: String;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
  ) {
    try {
      this.email = this.router.getCurrentNavigation().extras.state.email;
      this.oldPassword = this.router.getCurrentNavigation().extras.state.password;
    }
    catch (e) {
      this.router.navigate([''])
    }

  }

  ngAfterViewInit() {
    this.toastr.info('新しいパスワードを設定してください。', 'パスワード設定');
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'register-page');
    this.registerForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  CompleteNewPassword() {
    this.isValidFormSubmitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();
      const newPassword = this.registerForm.get("password").value;
      this.auth.signIn(this.email, this.oldPassword)
        .subscribe(
          result => {
            if (result['challengeName'] == "NEW_PASSWORD_REQUIRED") {
              this.auth.completeNewPassword(result, newPassword, {})
                .subscribe(
                  result => {
                    setTimeout(() => this.spinner.hide(), 500);
                    this.toastr.success('保存されました。', '成功');
                    this.router.navigate(['/']);
                  },
                  error => {
                    setTimeout(() => this.spinner.hide(), 500);
                    if (error.code == "CodeMismatchException") {
                      this.toastr.error('確認コードが間違えています。', 'エラー');
                    } else if (error.code == "InvalidPasswordException") {
                      this.toastr.error('パスワードの要件を満たしていません。', 'エラー');
                    }else{
                      this.toastr.error('パスワード変更に失敗しました。', 'エラー');
                    }
                  });
            }
            else {
              setTimeout(() => this.spinner.hide(), 500);
              this.router.navigate(['/']);
            }
          },
          error => {
            setTimeout(() => this.spinner.hide(), 500);
            this.toastr.error('パスワード変更に失敗しました。', 'エラー');
          });
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }
  }

  get f() { return this.registerForm.controls; }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'register-page');
  }
}
