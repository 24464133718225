import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';

import { DataStore } from '@aws-amplify/datastore';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { Tenant } from '../../../../../models';

@Component({
  selector: 'app-edittenant',
  templateUrl: './edittenant.component.html',
  styleUrls: ['./edittenant.component.scss']
})
export class EditTenantComponent implements OnInit {
  public updateForm: FormGroup;
  isValidFormSubmitted = null;
  editTarget;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
    try {
      this.editTarget = this.router.getCurrentNavigation().extras.state.target;
    }
    catch (e) {
      this.router.navigate([''])
    }
  }

  ngOnInit() {
    this.updateForm = this.formBuilder.group({
      name: [this.editTarget.name, [Validators.required, Validators.maxLength(64)]],
      license: [this.editTarget.licenseCode, [Validators.required, Validators.maxLength(40)]],
      domainList: [this.editTarget.domainList, [Validators.maxLength(512)]],
      expirationStartDate: [this.editTarget.expirationStartDate, [Validators.required]],
      expirationEndDate: [this.editTarget.expirationEndDate, [Validators.required]],
      mailaddressOfAlert: [this.editTarget.mailaddressOfAlert, [Validators.required, Validators.maxLength(256)]],
      monitoringInterval: [this.editTarget.monitoringInterval, [Validators.required, Validators.min(0), Validators.max(100)]],
      continuousMoveThreshold: [this.editTarget.continuousMoveThreshold, [Validators.required, Validators.min(1), Validators.max(600)]],
      sendAlertInterval: [this.editTarget.sendAlertInterval, [Validators.required, Validators.min(0), Validators.max(100)]],
      alertMessageRetentionPeriod: [this.editTarget.alertMessageRetentionPeriod, [Validators.required, Validators.min(1), Validators.max(365)]],
      licenseOfEscape: [this.editTarget.licenseOfEscape],
      licenseOfHighFever: [this.editTarget.licenseOfHighFever],
      licenseOfUnKnown: [this.editTarget.licenseOfUnKnown],
      licenseOfWatchOver: [this.editTarget.licenseOfWatchOver],
      licenseOfAutoUnlock: [this.editTarget.licenseOfAutoUnlock],
    });
  }

  private get(id: string) {
    return from(DataStore.query(Tenant, id));
  }

  onClickEditTenant() {
    this.isValidFormSubmitted = true;
    if (this.updateForm.valid) {
      this.spinner.show();
      let name = this.updateForm.get("name").value;
      let license = this.updateForm.get("license").value;
      let domainList = this.updateForm.get("domainList").value;
      let expirationStartDate = this.updateForm.get("expirationStartDate").value;
      let expirationEndDate = this.updateForm.get("expirationEndDate").value;
      let mailaddressOfAlert = this.updateForm.get("mailaddressOfAlert").value;
      let monitoringInterval = this.updateForm.get("monitoringInterval").value;
      let continuousMoveThreshold = this.updateForm.get("continuousMoveThreshold").value;
      let sendAlertInterval = this.updateForm.get("sendAlertInterval").value;
      let alertMessageRetentionPeriod = this.updateForm.get("alertMessageRetentionPeriod").value;
      let licenseOfEscape = false;
      if (this.updateForm.get("licenseOfEscape").value == true) {
        licenseOfEscape = true;
      }
      let licenseOfHighFever = false;
      if (this.updateForm.get("licenseOfHighFever").value == true) {
        licenseOfHighFever = true;
      }
      let licenseOfUnKnown = false;
      if (this.updateForm.get("licenseOfUnKnown").value == true) {
        licenseOfUnKnown = true;
      }
      let licenseOfWatchOver = false;
      if (this.updateForm.get("licenseOfWatchOver").value == true) {
        licenseOfWatchOver = true;
      }
      let licenseOfAutoUnlock = false;
      if (this.updateForm.get("licenseOfAutoUnlock").value == true) {
        licenseOfAutoUnlock = true;
      }
      this.get(this.editTarget.id).subscribe(item => {
        DataStore.save(
          Tenant.copyOf(item, updated => {
            updated.name = name;
            updated.licenseCode = license;
            updated.domainList = domainList;
            updated.expirationStartDate = expirationStartDate;
            updated.expirationEndDate = expirationEndDate;
            updated.mailaddressOfAlert = mailaddressOfAlert;
            updated.monitoringInterval = monitoringInterval;
            updated.continuousMoveThreshold = continuousMoveThreshold;
            updated.sendAlertInterval = sendAlertInterval;
            updated.alertMessageRetentionPeriod = alertMessageRetentionPeriod;
            updated.licenseOfEscape = licenseOfEscape;
            updated.licenseOfHighFever = licenseOfHighFever;
            updated.licenseOfUnKnown = licenseOfUnKnown;
            updated.licenseOfWatchOver = licenseOfWatchOver;
            updated.licenseOfAutoUnlock = licenseOfAutoUnlock;
          })
        ).then(result => {
          this.toastr.success('保存されました。', '成功');
          this.updateForm.reset();
          this.router.navigate(['/tenantmanagement'])
        }).catch(error => {
          this.toastr.error(error.message, 'エラー');
        });
      });
      setTimeout(() => this.spinner.hide(), 500);
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }

  }

  get f() { return this.updateForm.controls; }

  ngAfterViewInit() {
  }

}
