import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertSettingManagementRoutingModule } from './alertsettingmanagement-routing.module';

import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AuthService } from '../../../utils/services/auth.service';

import { AlertSettingManagementComponent } from './alertsettingmanagement.component';
import { CreateAlertSettingComponent } from './createalertsetting/createalertsetting.component';
import { EditAlertSettingComponent } from './editalertsetting/editalertsetting.component';
import { DeleteAlertSettingComponent } from './deletealertsetting/deletealertsetting.component';

@NgModule({
  declarations: [
    AlertSettingManagementComponent,
    CreateAlertSettingComponent,
    EditAlertSettingComponent,
    DeleteAlertSettingComponent
  ],
  imports: [
    CommonModule,
    AlertSettingManagementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    AmplifyAngularModule,
    DataTablesModule,
  ],
  providers: [AuthService, AmplifyService],
})
export class AlertSettingManagementModule { }
