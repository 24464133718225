import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TenantManagementRoutingModule } from './tenantmanagement-routing.module';

import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AuthService } from '../../../utils/services/auth.service';

import { TenantManagementComponent } from './tenantmanagement.component';
import { CreateTenantComponent } from './createtenant/createtenant.component';
import { EditTenantComponent } from './edittenant/edittenant.component';
import { DeleteTenantComponent } from './deletetenant/deletetenant.component';

@NgModule({
  declarations: [
    TenantManagementComponent,
    CreateTenantComponent,
    EditTenantComponent,
    DeleteTenantComponent
  ],
  imports: [
    CommonModule,
    TenantManagementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    AmplifyAngularModule,
    DataTablesModule,
  ],
  providers: [AuthService, AmplifyService],
})
export class TenantManagementModule { }
