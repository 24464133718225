import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  subscription: Subscription;

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.subscription = this.auth.getGroupName()
    .subscribe(result => {
      if( result == "SystemAdmin"){
        this.router.navigate(['/systemadmindashboard']);
      } else if(result == "TenantAdmin"){
        this.router.navigate(['/tenantadmindashboard']);
      } else if(result == "TenantUser"){
        this.router.navigate(['/tenantuserdashboard']);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
