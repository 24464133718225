export class AlertMessageSystemAdminRow {
    id: string;
    alertKind: string;
    name: string;
    content: string;
    detail: string;
    cameraId: string;
    cameraDescription: string;
    cameraThreshold: string;
    imege1Key: string;
    imege2Key: string;
    imege3Key: string;
    image1Url: string;
    image2Url: string;
    image3Url: string;
    alertStatus: string;
  }