<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6"><h1>対象者管理</h1></div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">

  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">対象者リスト <button type="button" (click)="rerender()" class="btn btn-default btn-sm"><i class="fas fa-sync-alt"></i></button></h3>
      </div>
      <div class="card-body">

        <div class="table-responsive" id='datatable'>
          <table summary="Target Person List" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-striped table-bordered dt-responsive nowrap hover" style="width:100%">
            <tfoot>
              <tr>
                <th><input type="text" placeholder="Search 氏名" name="searchName"/></th>
                <th><input type="text" placeholder="Search uid" name="searchUid"/></th>
                <th><input type="text" placeholder="Search 説明" name="searchDescription"/></th>
                <th><input type="text" placeholder="Search 管理番号" name="searchRoomNumber"/></th>
                <th><input type="text" placeholder="Search 有効・無効" name="searchEnable"/></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </div>

      </div>
      <div class="card-footer">
        <div class="row">
          <button (click)="onClickCreateTargetPerson()" type="submit" class="btn btn-primary">対象者作成</button>
        </div>
      </div>
    </div>
  </div>
</section>
