import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, from } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { AmplifyService } from 'aws-amplify-angular';
import { DataStore, Predicates } from '@aws-amplify/datastore';

import { AlertMessage } from '../../../../models';
import { AlertMessageSystemAdminRow } from './alertMessageSystemAdminRow';

@Component({
  selector: 'app-alertmessagesystemadminlist',
  templateUrl: './alertmessagesystemadminlist.component.html',
  styleUrls: ['./alertmessagesystemadminlist.component.scss']
})
export class AlertMessageSystemAdminListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  alertMessages;
  editTarget;


  constructor(
    private amplifyService: AmplifyService,
  ) { }


  ngOnInit() {
    // const limit = 0; 
    this.defaultList();
    this.dtOptions = {
      search: {
        regex: false,
      },
      ajax: (dataTablesParameters: any, callback) => {
        let dataList = []
        this.alertMessages.subscribe(async alertMessages => {
          for (var i = 0; i < alertMessages.length; i++) {
            let alertMessageSystemAdminRow = new AlertMessageSystemAdminRow();
            alertMessageSystemAdminRow.id = alertMessages[i].id;
            alertMessageSystemAdminRow.alertKind = alertMessages[i].alertKind;
            alertMessageSystemAdminRow.name = alertMessages[i].name;
            alertMessageSystemAdminRow.content = alertMessages[i].content;
            alertMessageSystemAdminRow.detail = alertMessages[i].detail;
            alertMessageSystemAdminRow.cameraId = alertMessages[i].cameraId;
            alertMessageSystemAdminRow.cameraDescription = alertMessages[i].cameraDescription;
            alertMessageSystemAdminRow.cameraThreshold = alertMessages[i].cameraThreshold;
            alertMessageSystemAdminRow.imege1Key = alertMessages[i].imege1Key;
            alertMessageSystemAdminRow.imege2Key = alertMessages[i].imege2Key;
            alertMessageSystemAdminRow.imege3Key = alertMessages[i].imege3Key;
            if (alertMessages[i].imege1Key != "") {
              alertMessageSystemAdminRow.image1Url = await this.amplifyService.storage().get(alertMessages[i].imege1Key, { level: 'public' });
            }
            else {
              alertMessageSystemAdminRow.image1Url = "";
            }
            if (alertMessages[i].imege2Key != "") {
              alertMessageSystemAdminRow.image2Url = await this.amplifyService.storage().get(alertMessages[i].imege2Key, { level: 'public' });
            }
            else {
              alertMessageSystemAdminRow.image2Url = "";
            }
            if (alertMessages[i].imege3Key != "") {
              alertMessageSystemAdminRow.image3Url = await this.amplifyService.storage().get(alertMessages[i].imege3Key, { level: 'public' });
            }
            else {
              alertMessageSystemAdminRow.image3Url = "";
            }
            alertMessageSystemAdminRow.alertStatus = alertMessages[i].alertMessageStatus.alertStatus;
            dataList.push(alertMessageSystemAdminRow);
          }
          callback({
            data: dataList,
          });
        })
      },
      columns: [
        {
          title: 'アラート種別',
          data: 'alertKind',
          name: 'alertKind',
        },
        {
          title: '氏名',
          data: 'name',
          name: 'name',
        },
        {
          title: 'コンテンツ',
          data: 'content',
          name: 'content',
        },
        {
          title: '詳細',
          data: 'detail',
          name: 'detail',
        },
        {
          title: 'カメラ識別ID',
          data: 'cameraId',
          name: 'cameraId',
        },
        {
          title: 'カメラ説明',
          data: 'cameraDescription',
          name: 'cameraDescription',
        },
        {
          title: 'カメラ閾値値',
          data: 'cameraThreshold',
          name: 'cameraThreshold',
        },
        {
          title: '写真1',
          name: 'image1',
          render: function (data: any, type: any, full: any) {
            if (full.image1Url == "") {
              return '<Text>写真なし</Text>';
            }
            else {
              return '<img src="' + full.image1Url + '">';
            }
          }
        },
        {
          title: '写真2',
          name: 'image2',
          render: function (data: any, type: any, full: any) {
            if (full.image2Url == "") {
              return '<Text>写真なし</Text>';
            }
            else {
              return '<img src="' + full.image2Url + '">';
            }
          }
        },
        {
          title: '写真3',
          name: 'image3',
          render: function (data: any, type: any, full: any) {
            if (full.image3Url == "") {
              return '<Text>写真なし</Text>';
            }
            else {
              return '<img src="' + full.image3Url + '">';
            }
          }
        },
        {
          title: 'ステータス',
          data: 'alertStatus',
          name: 'alertStatus',
        }
      ]
    };
  }

  list(limit){
    this.alertMessages = from(DataStore.query(AlertMessage, Predicates.ALL, {
      sort: s => s.shootingDataTime("DESCENDING"),
      page: 0,
      limit: limit
    }));
  }

  defaultList(){
    const todayUnixMs = Date.now(); // ex.1614524400123
    const todayUnix = Math.floor(todayUnixMs / 1000); // ex.1614524400(2021-03-01 00:00:00) 3rd 1614697200
    const twentyFourHoursAgo = todayUnix - 86400

    this.alertMessages = from(DataStore.query(AlertMessage, c => c.createDateTime("between", [twentyFourHoursAgo, todayUnix]), {
        sort: s => s.shootingDataTime("DESCENDING"),
      }));
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const limit = 0;
      this.list(limit)
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  list1day(){
    this.filterDate("1Day");
  }

  list3Days(){
    this.filterDate("3Days");
  }
       
  list1Week(){
    this.filterDate("1Week");
  }
  
  list1Month(){
    this.filterDate("1Month");
  }
  
  list3Months() {
    this.filterDate("3Months");
  }

  list6Months() {
    this.filterDate("6Months");
  }

  filterDate(period){
    const todayUnixMs = Date.now(); // ex.1614524400123
    const todayUnix = Math.floor(todayUnixMs / 1000); // ex.1614524400(2021-03-01 00:00:00) 3rd 1614697200
    const twentyFourHoursAgo = todayUnix - 86400
    const threeDaysAgo = todayUnix - 259200;
    const sevenDaysAgo = todayUnix - 604800;
    const thirtyOneDaysAgo = todayUnix - 2678400;
    const ninetyDaysAgo = todayUnix - 7776000;
    const oneEightyDaysAgo = todayUnix - 15552000;

    switch (period) {
      case "1Day":
        this.getDate(twentyFourHoursAgo, todayUnix);
        break;
      case "3Days":
        this.getDate(threeDaysAgo, todayUnix);      
        break;
      case "1Week":
        this.getDate(sevenDaysAgo, todayUnix);
        break;
      case "1Month":
        this.getDate(thirtyOneDaysAgo, todayUnix);
        break;
      case "3Months":
        this.getDate(ninetyDaysAgo, todayUnix);
        break;
      case "6Months":
        this.getDate(oneEightyDaysAgo, todayUnix);
        break;
      default:
        this.rerender();
    };
  };

  getDate(startDate, endDate){
　　this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.alertMessages = from(DataStore.query(AlertMessage, c => c.createDateTime("between", [startDate, endDate]), {
        sort: s => s.shootingDataTime("DESCENDING"),
      }));
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}
