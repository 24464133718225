/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:f073b9e7-f087-450e-a9ff-2fd5a8caeb8a",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_nkC7cq6qo",
    "aws_user_pools_web_client_id": "5s0a83qk6k54q1n445bmgdjnq6",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://r7nboexmc5f7zotjuip3xrnj3e.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "hitoterracerestapi",
            "endpoint": "https://f6q1dmql92.execute-api.ap-northeast-1.amazonaws.com/stg",
            "region": "ap-northeast-1"
        }
    ],
    "aws_content_delivery_bucket": "hitoterrace-web-stg",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d17eaymkm1qf1x.cloudfront.net",
    "aws_mobile_analytics_app_id": "e2a32bf014e14858b700d4c9ba35a25e",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_user_files_s3_bucket": "hitoterrace171036-stg",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
