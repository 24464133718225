<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6"><h1>対象者管理</h1></div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header"><h3 class="card-title">対象者作成</h3></div>
      <form [formGroup]="registerForm">
        <div class="card-body">

          <div class="input-group mb-3">
            <label class="col-md-4">氏名<small class="text-muted">&nbsp;&nbsp;64文字</small></label>
            <input type="text" formControlName="name" class="form-control" placeholder="Name"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.name.errors }" />
            <div *ngIf="isValidFormSubmitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Name is required</div>
              <div *ngIf="f.name.errors.maxlength">max 64 characters</div>
              <div *ngIf="f.name.errors.pattern">Includes restricted strings[" ,]</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">uid<small class="text-muted">&nbsp;&nbsp;40文字</small></label>
            <input type="text" formControlName="uid" class="form-control" placeholder="uid"
              pattern="[a-zA-Z0-9!#$%&'()*+-./:-@[-`{-~]*"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.uid.errors }" />
            <div *ngIf="isValidFormSubmitted && f.uid.errors" class="invalid-feedback">
              <div *ngIf="f.uid.errors.required">uid is required</div>
              <div *ngIf="f.uid.errors.maxlength">max 40 characters</div>
              <div *ngIf="f.uid.errors.pattern">Includes invalid strings or restricted strings[" ,]</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">説明<small class="text-muted">&nbsp;&nbsp;300文字</small></label>
            <input type="text" formControlName="description" class="form-control" placeholder="Description"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.description.errors }" />
            <div *ngIf="isValidFormSubmitted && f.description.errors" class="invalid-feedback">
              <div *ngIf="f.description.errors.required">Description is required</div>
              <div *ngIf="f.description.errors.maxlength">max 300 characters</div>
              <div *ngIf="f.description.errors.pattern">Includes restricted strings[" ,]</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">管理番号<small class="text-muted">&nbsp;&nbsp;8文字</small></label>
            <input type="text" formControlName="roomNumber" class="form-control" placeholder="Room Number"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.roomNumber.errors }" />
            <div *ngIf="isValidFormSubmitted && f.roomNumber.errors" class="invalid-feedback">
              <div *ngIf="f.roomNumber.errors.required">Room Number is required</div>
              <div *ngIf="f.roomNumber.errors.maxlength">max 8 characters</div>
              <div *ngIf="f.roomNumber.errors.pattern">Includes restricted strings[" ,]</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">有効／無効</label>
            <input type="checkbox" formControlName="enable" class="form-control" placeholder="enable" id="Enable" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">マスター画像１<small class="text-muted">（jpeg）</small></label>
            <input type="file" id="image1File" accept="image/jpeg" (change)="handleimage1FileInput($event)" >
            <img [src]="img1Src" alt="">
            <div *ngIf="this.image1FileType === 'unknown'" class="file-type-validator">jpegファイルを選択してください。</div>
            <div *ngIf="this.image1FileSize === 0" class="file-type-validator">画像サイズが基準を満たしていません。</div>
            <div *ngIf="this.isImage1MinSize === false" class="file-type-validator">80×80 pix 以上、1,920×1,080 pix 以下の画像を指定してください。</div>
            <div *ngIf="this.isImage1MaxSize === false" class="file-type-validator">80×80 pix 以上、1,920×1,080 pix 以下の画像を指定してください。</div>
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">マスター画像２<small class="text-muted">（jpeg）</small></label>
            <input type="file" id="image2File" accept="image/jpeg" (change)="handleimage2FileInput($event)">
            <img [src]="img2Src" alt="">
            <div *ngIf="this.image2FileType === 'unknown'" class="file-type-validator">jpegファイルを選択してください。</div>
            <div *ngIf="this.image2FileSize === 0" class="file-type-validator">画像サイズが基準を満たしていません。</div>
            <div *ngIf="this.isImage2MinSize === false" class="file-type-validator">80×80 pix 以上、1,920×1,080 pix 以下の画像を指定してください。</div>
            <div *ngIf="this.isImage2MaxSize === false" class="file-type-validator">80×80 pix 以上、1,920×1,080 pix 以下の画像を指定してください。</div>
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">マスター画像３<small class="text-muted">（jpeg）</small></label>
            <input type="file" id="image3File" accept="image/jpeg" (change)="handleimage3FileInput($event)">
            <img [src]="img3Src" alt="">
            <div *ngIf="this.image3FileType === 'unknown'" class="file-type-validator">jpegファイルを選択してください。</div>
            <div *ngIf="this.image3FileSize === 0" class="file-type-validator">画像サイズが基準を満たしていません。</div>
            <div *ngIf="this.isImage3MinSize === false" class="file-type-validator">80×80 pix 以上、1,920×1,080 pix 以下の画像を指定してください。</div>
            <div *ngIf="this.isImage3MaxSize === false" class="file-type-validator">80×80 pix 以上、1,920×1,080 pix 以下の画像を指定してください。</div>
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">マスター画像４<small class="text-muted">（jpeg）</small></label>
            <input type="file" id="image4File" accept="image/jpeg" (change)="handleimage4FileInput($event)">
            <img [src]="img4Src" alt="">
            <div *ngIf="this.image4FileType === 'unknown'" class="file-type-validator">jpegファイルを選択してください。</div>
            <div *ngIf="this.image4FileSize === 0" class="file-type-validator">画像サイズが基準を満たしていません。</div>
            <div *ngIf="this.isImage4MinSize === false" class="file-type-validator">80×80 pix 以上、1,920×1,080 pix 以下の画像を指定してください。</div>
            <div *ngIf="this.isImage4MaxSize === false" class="file-type-validator">80×80 pix 以上、1,920×1,080 pix 以下の画像を指定してください。</div>
          </div>
          <div class="input-group mb-3">
            <label class="col-md-4">マスター画像５<small class="text-muted">（jpeg）</small></label>
            <input type="file" id="image5File" accept="image/jpeg" (change)="handleimage5FileInput($event)">
            <img [src]="img5Src" alt="">
            <div *ngIf="this.image5FileType === 'unknown'" class="file-type-validator">jpegファイルを選択してください。</div>
            <div *ngIf="this.image5FileSize === 0" class="file-type-validator">画像サイズが基準を満たしていません。</div>
            <div *ngIf="this.isImage5MinSize === false" class="file-type-validator">80×80 pix 以上、1,920×1,080 pix 以下の画像を指定してください。</div>
            <div *ngIf="this.isImage5MaxSize === false" class="file-type-validator">80×80 pix 以上、1,920×1,080 pix 以下の画像を指定してください。</div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">アラート設定</label>
            <select formControlName="alertSettingMasterId" class="form-control" #alertSettingMasterId
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.alertSettingMasterId.errors }">
              <option *ngFor="let alertSetting of alertSettings" [value]="alertSetting.id">
                {{ alertSetting.name }}
              </option>
            </select>
            <div *ngIf="isValidFormSubmitted && f.alertSettingMasterId.errors" class="invalid-feedback">
              <div *ngIf="f.alertSettingMasterId.errors.required">Alert Setting is required</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">解錠許可カメラA</label>
            <input type="checkbox" formControlName="unlockAreaA" class="form-control" placeholder="unlockAreaA" id="unlockAreaA" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">解錠許可カメラB</label>
            <input type="checkbox" formControlName="unlockAreaB" class="form-control" placeholder="unlockAreaB" id="unlockAreaB" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">解錠許可カメラC</label>
            <input type="checkbox" formControlName="unlockAreaC" class="form-control" placeholder="unlockAreaC" id="unlockAreaC" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">解錠許可カメラD</label>
            <input type="checkbox" formControlName="unlockAreaD" class="form-control" placeholder="unlockAreaD" id="unlockAreaD" />
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">解錠許可カメラE</label>
            <input type="checkbox" formControlName="unlockAreaE" class="form-control" placeholder="unlockAreaE" id="unlockAreaE" />
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <button (click)="onClickCreateTargetPerson()" type="submit" class="btn btn-primary">作成</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
