import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import {SystemAdminDashboardComponent} from './systemadmindashboard/systemadmindashboard.component';
import {TenantAdminDashboardComponent} from './tenantadmindashboard/tenantadmindashboard.component';
import {TenantUserDashboardComponent} from './tenantuserdashboard/tenantuserdashboard.component';
import { SystemAdminOnlyAuthGuard } from '../../../utils/guards/systemadminonly-auth.guard';
import { TenantAdminOnlyAuthGuard } from '../../../utils/guards/tenantadminonly-auth.guard';
import { TenantUserOnlyAuthGuard } from '../../../utils/guards/tenantuseronly-auth.guard';

const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
    },
    {
        path: 'systemadmindashboard',
        component: SystemAdminDashboardComponent,
        canActivate: [SystemAdminOnlyAuthGuard],
    },
    {
        path: 'tenantadmindashboard',
        component: TenantAdminDashboardComponent,
        canActivate: [TenantAdminOnlyAuthGuard],
    },
    {
        path: 'tenantuserdashboard',
        component: TenantUserDashboardComponent,
        canActivate: [TenantUserOnlyAuthGuard],
    },


];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
