import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthService } from '../utils/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  isValidFormSubmitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'login-page');
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  logIn() {
    this.isValidFormSubmitted = true;
    if (this.loginForm.valid) {
      this.spinner.show();
      const email = this.loginForm.get("email").value;
      const password = this.loginForm.get("password").value;
      this.auth.signIn(email, password)
        .subscribe(
          result => {
            setTimeout(() => this.spinner.hide(), 500);
            if (result['challengeName'] == "NEW_PASSWORD_REQUIRED") {
              this.router.navigate(['/completenewpassword'], { state: { email: email, password: password } });
            }
            else {
              this.router.navigate(['/']);
            }
          },
          error => {
            setTimeout(() => this.spinner.hide(), 500);
            this.toastr.error('メールアドレスかパスワードが間違えています。', 'エラー');
          });
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }
  }

  get f() { return this.loginForm.controls; }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-page');
  }
}
