import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthService } from '../utils/services/auth.service';
import { MustMatch } from '../utils/must-match.validator';

@Component({
  selector: 'app-confirmsignup',
  templateUrl: './confirmsignup.component.html',
  styleUrls: ['./confirmsignup.component.scss']
})
export class ConfirmSignUpComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  isValidFormSubmitted = false;
  private email: String;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
  ) {
    try {
      this.email = this.router.getCurrentNavigation().extras.state.email;
    }
    catch (e) {
      this.router.navigate([''])
    }
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'register-page');
    this.registerForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    this.toastr.info('確認コードをメールで送信しました。確認して入力してください。', '確認コード送信');
  }

  ConfirmSignUp() {
    this.isValidFormSubmitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();
      const code = this.registerForm.get("code").value;
      this.auth.confirmSignUp(this.email, code)
        .subscribe(
          result => {
            setTimeout(() => this.spinner.hide(), 500);
            this.router.navigate(['/login']);
          },
          error => {
            setTimeout(() => this.spinner.hide(), 500);
            console.log(error);
            if (error.code == "CodeMismatchException") {
              this.toastr.error('確認コードが間違えています。', 'エラー');
            }
          });
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }
  }

  get f() { return this.registerForm.controls; }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'register-page');
  }
}
