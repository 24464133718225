import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthService } from '../utils/services/auth.service';
import { AppService } from '../utils/services/app.service';
import { APIService } from '../API.service';
import { MustMatch } from '../utils/must-match.validator';
import awsconfig from '../../aws-exports';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  isValidFormSubmitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'register-page');
    this.registerForm = this.formBuilder.group({
      license: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  SignUp() {
    this.isValidFormSubmitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();
      const license = this.registerForm.get("license").value;
      const graphqlendpoint = awsconfig.aws_appsync_graphqlEndpoint;
      const email = this.registerForm.get("email").value;
      const password = this.registerForm.get("password").value;
      var attributes =
      {
        'custom:license': license,
        'custom:graphqlendpoint': graphqlendpoint,
      }
      this.auth.signUp(email, password, attributes)
        .subscribe(
          result => {
            setTimeout(() => this.spinner.hide(), 500);
            this.router.navigate(['/confirmsignup'], { state: { email: email } });
          },
          error => {
            setTimeout(() => this.spinner.hide(), 500);
            if (error.code == "InvalidPasswordException") {
              this.toastr.error('パスワードの要件を満たしていません。', 'エラー');
            }
            else if (error.code == "UsernameExistsException") {
              this.toastr.error('すでにサインアップ済みのメールアドレスです。', 'エラー');
            }
            else if (error.code == "UserLambdaValidationException") {
              if (error.message == "PreSignUp failed with error There are no tenants..") {
                this.toastr.error('指定されたライセンス番号が存在しません。', 'エラー');
              } else if (error.message == "PreSignUp failed with error Your email domain is not allowed..") {
                this.toastr.error('指定されたメールアドレスのドメインは登録できません。', 'エラー');
              }
            }
            else {
              this.toastr.error('サインアップに失敗しました。しばらくしてから再度実行してください。', 'エラー');
            }
          });
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }
  }

  get f() { return this.registerForm.controls; }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'register-page');
  }
}
