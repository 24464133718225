import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { API } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DataStore, syncExpression } from '@aws-amplify/datastore';

import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';
import { AppService } from '../../../../utils/services/app.service';

@Component({
  selector: 'app-createaccount',
  templateUrl: './createaccount.component.html',
  styleUrls: ['./createaccount.component.scss']
})
export class CreateAccountComponent implements OnInit {
  public registerForm: FormGroup;
  isValidFormSubmitted = null;
  tenantId: string = "";
  subscription;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private appService: AppService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit() {
    this.subscription = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
      });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.maxLength(256), Validators.pattern('[^",]+')]],
      groupName: ['', [Validators.required, Validators.maxLength(256), Validators.pattern('[^",]+')]],
      //expirationEndDate: ['', [Validators.required]],
      // isTenantAdmin: [false],
      isTenantAdmin: [{value: true, disabled: true}],
    });
  }

  async onClickCreateAccount() {
    this.isValidFormSubmitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();
      let email = this.registerForm.get("email").value;
      let groupName = this.registerForm.get("groupName").value;
      //let expirationEndDate = this.registerForm.get("expirationEndDate").value;
      let expirationEndDate = '2030/12/31'
      let isTenantAdmin = true;
      // let isTenantAdmin = false;
      // if (this.registerForm.get("isTenantAdmin").value == true) {
      //   isTenantAdmin = true;
      // }
      const myInit = {
        body: {
          email: email,
          tenantId: this.tenantId,
          groupName: groupName,
          expirationEndDate: expirationEndDate,
          isTenantAdmin: isTenantAdmin,
        },
        headers: {}
      };
      API
        .put('hitoterracerestapi', '/accounts', myInit)
        .then(response => {
          if (response.body.code == 100) {
            this.toastr.error('すでに同じメールアドレスが登録されています。', 'エラー');
          } else if (response.body.code == 101) {
            this.toastr.error('該当のユーザーが存在しません。', 'エラー');
          } else {
            this.toastr.success('保存されました。', '成功');
            this.toastr.success('しばらく待ってからリストを更新してください。', '成功');
            this.registerForm.reset();
            this.router.navigate(['/accountmanagement'])
          }
          setTimeout(() => this.spinner.hide(), 500);
        })
        .catch(error => {
          this.toastr.error(error.response, 'エラー');
          setTimeout(() => this.spinner.hide(), 500);
        });
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }

  }

  get f() { return this.registerForm.controls; }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
