import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';

import { TenantUserAuthGuard } from '../../../utils/guards/tenantuser-auth.guard';

import { TargetPersonManagementComponent } from './targetpersonmanagement.component';
import { CreateTargetPersonComponent } from './createtargetperson/createtargetperson.component';
import { EditTargetPersonComponent } from './edittargetperson/edittargetperson.component';
import { DeleteTargetPersonComponent } from './deletetargetperson/deletetargetperson.component';

const routes: Routes = [
    {
        path: '',
        component: TargetPersonManagementComponent,
        canActivate: [TenantUserAuthGuard],
    },
    {
        path: 'create',
        component: CreateTargetPersonComponent,
        canActivate: [TenantUserAuthGuard],
    },
    {
        path: 'edit',
        component: EditTargetPersonComponent,
        canActivate: [TenantUserAuthGuard],
    },
    {
        path: 'delete',
        component: DeleteTargetPersonComponent,
        canActivate: [TenantUserAuthGuard],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TargetPersonManagementRoutingModule { }
