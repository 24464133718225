import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, from } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { DataStore,syncExpression } from '@aws-amplify/datastore';

import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../models';
import { AmplifyService } from 'aws-amplify-angular';

import { TargetPersonManagementRow } from './targetPersonManagementRow';
import { AppService } from '../../../utils/services/app.service';

@Component({
  selector: 'app-targetpersonmanagement',
  templateUrl: './targetpersonmanagement.component.html',
  styleUrls: ['./targetpersonmanagement.component.scss']
})
export class TargetPersonManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  targetPersons;
  editTarget;
  tenantId: string = "";
  subscription1;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private amplifyService: AmplifyService,
    private appService: AppService,
  ) { }

  ngOnInit() {
    this.subscription1 = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
        this.list();
      });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

    this.dtOptions = {
      search: {
        regex: false,
      },
      scrollX: true,
      scrollCollapse: true,
      order: [[0, 'asc']],
      // stateSave: true,
      // columnDefs: [
      //   { targets: 0, width: 100 }, // 氏名
      //   { targets: 1, width: 100 }, // uid
      //   { targets: 2, width: 100 }, // 説明
      //   { targets: 3, width: 100 }, // 管理番号
      //   { targets: 4, width: 100 }, // 有効・無効
      //   { targets: 5, width: 250 }, // マスター画像1
      //   { targets: 6, width: 250 }, // マスター画像2
      //   { targets: 7, width: 250 }, // マスター画像3
      //   { targets: 8, width: 250 }, // マスター画像4
      //   { targets: 9, width: 250 }, // マスター画像5
      //   { targets: 10, width: 50 }, // 操作
      // ],
      ajax: (dataTablesParameters: any, callback) => {
        let dataList = []

        this.targetPersons.subscribe(async targetPersons => {
          for (var i = 0; i < targetPersons.length; i++) {
            let targetPersonManagementRow = new TargetPersonManagementRow();

            targetPersonManagementRow.id          = targetPersons[i].id;
            targetPersonManagementRow.name        = targetPersons[i].name;
            targetPersonManagementRow.uid         = targetPersons[i].uid;
            targetPersonManagementRow.description = targetPersons[i].description;
            targetPersonManagementRow.roomNumber  = targetPersons[i].roomNumber;
            targetPersonManagementRow.enable      = targetPersons[i].enable;
            targetPersonManagementRow.camera_type = targetPersons[i].camera_type;
            targetPersonManagementRow.serialNumber = targetPersons[i].serialNumber;
            targetPersonManagementRow.imege1Key   = targetPersons[i].imege1Key;

            if ((targetPersons[i].thumbnail1Key != '' && targetPersons[i].thumbnail1Key !== null)&&( targetPersons[i].thumbnail1Key !== undefined)){
              targetPersonManagementRow.image1Url = await this.amplifyService.storage().get(targetPersons[i].thumbnail1Key.slice(7), { level: 'public' });
            } else {
              targetPersonManagementRow.image1Url = '';
            }

            if ((targetPersons[i].thumbnail2Key != '' && targetPersons[i].thumbnail2Key !== null)&&( targetPersons[i].thumbnail2Key !== undefined)){
              targetPersonManagementRow.image2Url = await this.amplifyService.storage().get(targetPersons[i].thumbnail2Key.slice(7), { level: 'public' });
            } else {
              targetPersonManagementRow.image2Url = '';
            }

            if ((targetPersons[i].thumbnail3Key != '' && targetPersons[i].thumbnail3Key !== null)&&( targetPersons[i].thumbnail3Key !== undefined)){
              targetPersonManagementRow.image3Url = await this.amplifyService.storage().get(targetPersons[i].thumbnail3Key.slice(7), { level: 'public' });
            } else {
              targetPersonManagementRow.image3Url = '';
            }

            if ((targetPersons[i].thumbnail4Key != '' && targetPersons[i].thumbnail4Key !== null)&&( targetPersons[i].thumbnail4Key !== undefined)){
              targetPersonManagementRow.image4Url = await this.amplifyService.storage().get(targetPersons[i].thumbnail4Key.slice(7), { level: 'public' });
            } else {
              targetPersonManagementRow.image4Url = '';
            }

            if ((targetPersons[i].thumbnail5Key != '' && targetPersons[i].thumbnail5Key !== null)&&( targetPersons[i].thumbnail5Key !== undefined)){
              targetPersonManagementRow.image5Url = await this.amplifyService.storage().get(targetPersons[i].thumbnail5Key.slice(7), { level: 'public' });
            } else {
              targetPersonManagementRow.image5Url = '';
            }

            dataList.push(targetPersonManagementRow);
          }
            callback({
              data: dataList,
            });
          })
/*
        this.list();
        this.targetPersons.subscribe(resp => {
          callback({
            data: resp,
          });
        })
*/
      },
      columns: [
        {
          title: '氏名',
          data: 'name',
          name: 'name',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: 'uid',
          data: 'uid',
          name: 'uid',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: '説明',
          data: 'description',
          name: 'description',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: '管理番号',
          name: 'roomNumber',
          render: function (data: any, type: any, full: any) {
            if (full.camera_type == 'HIK-A70') {
              return full.serialNumber;
            } else {
              return full.roomNumber;
            }
          }
        },
        {
          title: '有効・無効',
//        data: 'enable',
          name: 'enable',
          render: function (data: any, type: any, full: any) {
            if (full.enable === true) {
              return '<Text>有効</Text>';
            } else {
              return '<Text>無効</Text>';
            }
          }
        },
        {
          title: 'マスター画像１',
          name: 'image1',
          render: function (data: any, type: any, full: any) {
            if (full.image1Url == '') {
              return '<Text>写真なし</Text>';
            } else {
              return '<img src="' + full.image1Url + '">';
            }
          }
        },
        {
          title: 'マスター画像２',
          name: 'image2',
          render: function (data: any, type: any, full: any) {
            if (full.image2Url == '') {
              return '<Text>写真なし</Text>';
            } else {
              return '<img src="' + full.image2Url + '">';
            }
          }
        },
        {
          title: 'マスター画像３',
          name: 'image3',
          render: function (data: any, type: any, full: any) {
            if (full.image3Url == '') {
              return '<Text>写真なし</Text>';
            } else {
              return '<img src="' + full.image3Url + '">';
            }
          }
        },
        {
          title: 'マスター画像４',
          name: 'image4',
          render: function (data: any, type: any, full: any) {
            if (full.image4Url == '') {
              return '<Text>写真なし</Text>';
            } else {
              return '<img src="' + full.image4Url + '">';
            }
          }
        },
        {
          title: 'マスター画像５',
          name: 'image5',
          render: function (data: any, type: any, full: any) {
            if (full.image5Url == '') {
              return '<Text>写真なし</Text>';
            } else {
              return '<img src="' + full.image5Url + '">';
            }
          }
        },
        {
          title: '操作',
          orderable: false,
          render: function (data: any, type: any, full: any) {
            // HIKから同期登録された対象者の編集操作を不可とする
            if (full.camera_type == 'HIK-A70') {
              return '<button type="button" delete-targetperson-id="' + full.id + '" class="btn btn-xs btn-warning margin-r-5">削除</button></div>';
            } else {
              return '<button type="button" edit-targetperson-id="' + full.id + '" class="btn btn-xs btn-info margin-r-5">編集</button>' +
                     '<button type="button" delete-targetperson-id="' + full.id + '" class="btn btn-xs btn-warning margin-r-5">削除</button></div>';
            }
          }
        }
      ]
    };
  }

  list() {
    this.targetPersons = from(DataStore.query(TargetPersonMaster, c => c.tenantId("eq", this.tenantId)));
  }

  private get(id: string) {
    return from(DataStore.query(TargetPersonMaster, id));
  }

  onClickCreateTargetPerson() {
    this.router.navigate(['/targetpersonmanagement/create'])
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.list();
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.renderer.listen('document', 'click', (event) => {
      this.targetPersons.subscribe(resp => {
        if (event.target.hasAttribute("edit-targetperson-id")) {
          resp.forEach(element => {
            if (element.id == event.target.getAttribute("edit-targetperson-id")) {
              this.get(element.id).subscribe(item => {
                this.router.navigate(['/targetpersonmanagement/edit'], { state: { target: item } });
              });
            }
          });
        }
        else if (event.target.hasAttribute("delete-targetperson-id")) {
          resp.forEach(element => {
            if (element.id == event.target.getAttribute("delete-targetperson-id")) {
              this.get(element.id).subscribe(item => {
                this.router.navigate(['/targetpersonmanagement/delete'], { state: { target: item } });
              });
            }
          });
        }

      })
    });
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that
              .search(this['value'])
              .draw();
          }
        });
      });
    });
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.dtTrigger.unsubscribe();
  }

}
