import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';

import { TenantAdminAuthGuard } from '../../../utils/guards/tenantadmin-auth.guard';


import { AlertMessageTenantAdminListComponent } from './alertmessagetenantadminlist.component';

const routes: Routes = [
    {
        path: '',
        component: AlertMessageTenantAdminListComponent,
        canActivate: [TenantAdminAuthGuard],
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlertMessageTenantAdminListRoutingModule { }
