import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';

import { AuthGuard } from '../utils/guards/auth.guard';
import { SystemAdminAuthGuard } from '../utils/guards/systemadmin-auth.guard';
import { TenantAdminAuthGuard } from '../utils/guards/tenantadmin-auth.guard';

import { MainComponent } from './main.component';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { AlertMessageSystemAdminListModule } from './pages/alertmessagesystemadminlist/alertmessagesystemadminlist.module';
import { AlertMessageTenantAdminListModule } from './pages/alertmessagetenantadminlist/alertmessagetenantadminlist.module';
import { AlertMessageListUserModule } from './pages/alertmessagetenantuserlist/AlertMessageTenantUserList.module';
import { TargetPersonManagementModule } from './pages/targetpersonmanagement/targetpersonmanagement.module';
import { TenantManagementModule } from './pages/tenantmanagement/tenantmanagement.module';
import { AccountManagementModule } from './pages/accountmanagement/accountmanagement.module';
import { AlertSettingManagementModule } from './pages/alertsettingmanagement/alertsettingmanagement.module';
import { TestAlertMessageModule } from './pages/testalertmessage/testalertmessage.module';
import { ProfileModule } from './pages/profile/profile.module';
import { TenantSettingModule } from './pages/tenantsetting/tenantsetting.module';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                loadChildren: () => ProfileModule,
            },
            {
                path: 'tenantsetting',
                loadChildren: () => TenantSettingModule,
            },
            {
                path: 'alertmessagetenantadminlist',
                loadChildren: () => AlertMessageTenantAdminListModule,
            },
            {
                path: 'alertmessagesystemadminlist',
                loadChildren: () => AlertMessageSystemAdminListModule,
            },
            // {
            //     path: 'alertmessagetenantuserlist',
            //     loadChildren: () => AlertMessageListUserModule,
            // },
            {
                path: 'targetpersonmanagement',
                loadChildren: () => TargetPersonManagementModule,
            },
            {
                path: 'tenantmanagement',
                loadChildren: () => TenantManagementModule,
            },
            {
                path: 'accountmanagement',
                loadChildren: () => AccountManagementModule,
            },
            {
                path: 'alertsettingmanagement',
                loadChildren: () => AlertSettingManagementModule,
            },
            {
                path: 'testalertmessage',
                loadChildren: () => TestAlertMessageModule,
            },
            {
                path: '',
                loadChildren: () => DashboardModule
            }
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MainRoutingModule { }
