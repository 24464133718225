import { Component, OnInit, Renderer2, ViewChild, ComponentFactory, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../models';
import { AppService } from '../utils/services/app.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  public sidebarMenuOpened = true;
  @ViewChild('contentWrapper', { static: false }) contentWrapper;
  tenantId: string = "";
  subscription;

  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    ) {}

  ngOnInit() {
    // this.subscription = this.appService.getTenantId()
    //   .subscribe(result => {
    //     this.tenantId = result;
    //   });

    // DataStore.configure({
    //   // maxRecordsToSync: 3000,
    //   syncExpressions: [
    //     syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
    //     syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId)),
    //     syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId)),
    //     syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId)),
    //     syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
    //     syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
    //     syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
    //   ]
    // });
  }

  mainSidebarHeight(height) {
    this.renderer.setStyle(
      this.contentWrapper.nativeElement,
      'min-height',
      height - 114 + 'px'
    );
  }

  toggleMenuSidebar() {
    console.log('sidebarMenuCollapsed', this.sidebarMenuOpened);
    if (this.sidebarMenuOpened) {
      this.renderer.removeClass(document.body, 'sidebar-open');
      this.renderer.addClass(document.body, 'sidebar-collapse');
      this.sidebarMenuOpened = false;
    } else {
      this.renderer.removeClass(document.body, 'sidebar-collapse');
      this.renderer.addClass(document.body, 'sidebar-open');
      this.sidebarMenuOpened = true;
    }
  }
}
