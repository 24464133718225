import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AccountManagementRoutingModule } from './accountmanagement-routing.module';

import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AuthService } from '../../../utils/services/auth.service';

import { AccountManagementComponent } from './accountmanagement.component';
import { CreateAccountComponent } from './createaccount/createaccount.component';
import { EditAccountComponent } from './editaccount/editaccount.component';
import { DeleteAccountComponent } from './deleteaccount/deleteaccount.component';

@NgModule({
  declarations: [
    AccountManagementComponent,
    CreateAccountComponent,
    EditAccountComponent,
    DeleteAccountComponent
  ],
  imports: [
    CommonModule,
    AccountManagementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    AmplifyAngularModule,
    DataTablesModule,
  ],
  providers: [AuthService, AmplifyService],
})
export class AccountManagementModule { }
