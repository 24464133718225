import { Injectable } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public user = {
    firstName: '',
    lastName: '',
    image: ''
  };

  constructor(
    private auth: AuthService
  ) { }


  logout() {
    this.auth.signOut();
  }


  getTenantId() {
    return this.auth.getTenantId();
  }
}
