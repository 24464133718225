<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>プロファイル
        </h1>
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Change Password</h3>
      </div>
      <form [formGroup]="registerForm">
        <div class="card-body">

          <div class="input-group mb-3">
            <input type="password" formControlName="oldPassword" class="form-control" placeholder="Old Password"
            [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.oldPassword.errors }"/>
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
            <div *ngIf="isValidFormSubmitted && f.oldPassword.errors" class="invalid-feedback">
              <div *ngIf="f.oldPassword.errors.required">Password is required</div>
              <div *ngIf="f.oldPassword.errors.minlength">Password must be at least 6 characters</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <input type="password" formControlName="newPassword" class="form-control" placeholder="New Password"
              [ngClass]="{ 'is-invalid': (isValidFormSubmitted && f.newPassword.errors) || invalidPasswordException }" />
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
            <div *ngIf="isValidFormSubmitted && f.newPassword.errors" class="invalid-feedback">
              <div *ngIf="f.newPassword.errors.required">Password is required</div>
              <div *ngIf="f.newPassword.errors.minlength">Password must be at least 6 characters</div>
            </div>
          </div>

          <div class="input-group mb-3">
            <input type="password" formControlName="confirmPassword" class="form-control"
              placeholder="Retype Password" [ngClass]="{ 'is-invalid': (isValidFormSubmitted && f.confirmPassword.errors) || invalidPasswordException }"/>
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
            <div *ngIf="isValidFormSubmitted && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
              <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
            </div>
          </div>
          <div class="alert alert-info ">
            <p>パスワードは6文字以上、数字、大文字、小文字を必要とします。</p>
          </div>
        </div>
        <div class="card-footer">
          <button (click)="onClickEditPassword()" type="submit" class="btn btn-primary">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</section>