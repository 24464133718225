<div class="register-box">
  <div class="register-logo">
    <a href="../../index2.html"><img src="assets/img/hitoterrace_logo.png" alt="HitoTerrace Logo"></a>
  </div>

  <div class="card">
    <div class="card-body register-card-body">
      <p class="login-box-msg">Confirm code</p>

      <form [formGroup]="registerForm">
        <div class="input-group mb-3">
          <input type="text" formControlName="code" class="form-control" placeholder="Code"
            [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.code.errors }" />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-key"></span>
            </div>
          </div>
          <div *ngIf="isValidFormSubmitted && f.code.errors" class="invalid-feedback">
            <div *ngIf="f.code.errors.required">Code is required</div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
          </div>
          <!-- /.col -->
          <div class="col-4">
            <button (click)="ConfirmSignUp()" type="submit" class="btn btn-primary btn-block btn-flat">
              Confirm
            </button>
          </div>
          <!-- /.col -->
        </div>
      </form>
      <a [routerLink]="['/login']" class="text-center">I already have a membership</a>
    </div>
    <!-- /.form-box -->
  </div>
  <!-- /.card -->
</div>