import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthService } from './utils/services/auth.service';

import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './signup/signup.component';
import { ConfirmSignUpComponent } from './confirmsignup/confirmsignup.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { ConfirmForgotPasswordComponent } from './confirmforgotpassword/confirmforgotpassword.component';
import { CompleteNewPasswordComponent } from './completenewpassword/completenewpassword.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    ConfirmSignUpComponent,
    ForgotPasswordComponent,
    ConfirmForgotPasswordComponent,
    CompleteNewPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, 
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: false
    }),
    AmplifyAngularModule,
    NgbModule,
    NgxSpinnerModule
  ],
  providers: [AuthService, AmplifyService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
