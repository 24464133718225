<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <!--h1>TenantAdmin Dashboard</h1-->
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <h2>{{tenantId}}</h2>
    <h3>管理者</h3>
  </div>
</section>