<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleMenuSidebar.emit()"
        ><em class="fas fa-bars"></em></a>
    </li>
    <!-- <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="['/']" class="nav-link">Home</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="['/blank']" class="nav-link">Contact</a>
    </li> -->
  </ul>

  <!-- SEARCH FORM -->
  <!-- <form class="form-inline ml-3" [formGroup]="searchForm">
    <div class="input-group input-group-sm">
      <input
        class="form-control form-control-navbar"
        type="search"
        placeholder="Search"
        aria-label="Search"
      />
      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <em class="fas fa-search"></em>
        </button>
      </div>
    </div>
  </form> -->

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <!-- Messages Dropdown Menu -->
    <!-- <app-messages-dropdown-menu></app-messages-dropdown-menu> -->
    <!-- Notifications Dropdown Menu -->
    <!-- <app-notifications-dropdown-menu></app-notifications-dropdown-menu> -->
    <li class="nav-item">
      <a
        class="nav-link"
        (click)="logout()"
      >
        <em class="fas fa-power-off"></em>
      </a>
    </li>
  </ul>
</nav>
<!-- /.navbar -->
