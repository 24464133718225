<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>テナント管理</h1>
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">テナントリスト <button type="button" (click)="rerender()" class="btn btn-default btn-sm"><i class="fas fa-sync-alt"></i></button></h3>
      </div>
      <div class="card-body">

        <div class="table-responsive">
          <table summary="Tenant List" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-striped table-bordered dt-responsive nowrap hover" style="width:100%"></table>
        </div>

      </div>
      <div class="card-footer">
        <div class="row">
          <button (click)="onClickCreateTenant()" type="submit" class="btn btn-primary">テナント作成</button>
        </div>
      </div>
    </div>
  </div>
</section>