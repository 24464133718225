import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';

import { AlertMessageSystemAdminListRoutingModule } from './alertmessagesystemadminlist-routing.module';

import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AuthService } from '../../../utils/services/auth.service';

import { AlertMessageSystemAdminListComponent } from './alertmessagesystemadminlist.component';

@NgModule({
  declarations: [
    AlertMessageSystemAdminListComponent,
  ],
  imports: [
    CommonModule,
    AlertMessageSystemAdminListRoutingModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    AmplifyAngularModule,
    DataTablesModule,
  ],
  providers: [AuthService, AmplifyService],
})
export class AlertMessageSystemAdminListModule { }
