import {
  Component,
  OnInit,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { AppService } from 'src/app/utils/services/app.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public appService: AppService,
    private auth: AuthService
  ) { }

  subscription1: Subscription;
  subscription2: Subscription;
//subscription3: Subscription;
//tenantId: string = '';
//tenantName: string = '';
  userName: string = '';
  systemAdmin: boolean = false;
  systemAdminOnly: boolean = false;
  tenantAdmin: boolean = false;
  tenantAdminOnly: boolean = false;
  tenantUser: boolean = false;
  tenantUserOnly: boolean = false;

  ngOnInit() {
/*
    this.subscription3 = this.auth.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
      });
*/
    this.subscription1 = this.auth.getLoginEmail()
      .subscribe(result => {
        this.userName = result;
      });
    this.subscription2 = this.auth.getGroupName()
      .subscribe(result => {
        if (result == 'SystemAdmin') {
          this.systemAdmin     = true;
          this.systemAdminOnly = true;
          this.tenantAdmin     = true;
          this.tenantAdminOnly = false;
          this.tenantUser      = true;
          this.tenantUserOnly  = false;
        } else if (result == 'TenantAdmin') {
          this.systemAdmin     = false;
          this.systemAdminOnly = false;
          this.tenantAdmin     = true;
          this.tenantAdminOnly = true;
          this.tenantUser      = true;
          this.tenantUserOnly  = false;
        } else if (result == 'TenantUser') {
          this.systemAdmin     = false;
          this.systemAdminOnly = false;
          this.tenantAdmin     = false;
          this.tenantAdminOnly = false;
          this.tenantUser      = true;
          this.tenantUserOnly  = true;
        }
      });
  }

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
//  this.subscription3.unsubscribe();
  }
}
