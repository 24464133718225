<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>テナント</h1>
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">テナント設定</h3>
      </div>
      <form [formGroup]="registerForm">
        <div class="card-body">

          <div class="input-group mb-3">
            <label class="col-md-4">連続移動の閾値<small class="text-muted">（秒）</small></label>
            <input type="number" formControlName="continuousMoveThreshold" class="form-control col-md-8"
              placeholder="Continuous Move Threshold"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.continuousMoveThreshold.errors }" />
            <div *ngIf="isValidFormSubmitted && f.continuousMoveThreshold.errors" class="invalid-feedback">
              <div *ngIf="f.continuousMoveThreshold.errors.required">Continuous Move Threshold is required</div>
              <div *ngIf="f.continuousMoveThreshold.errors.min">Continuous Move Threshold is 1 ~ 600</div>
              <div *ngIf="f.continuousMoveThreshold.errors.max">Continuous Move Threshold is 1 ~ 600</div>
              <div *ngIf="f.continuousMoveThreshold.errors.pattern">Includes restricted strings[" ,]</div>
            </div>
          </div>

          <!-- <div class="input-group mb-3">
            <label class="col-md-4">アラート送信間隔<small class="text-muted">（分）0：無効</small></label>
            <input type="number" formControlName="sendAlertInterval" class="form-control col-md-8"
              placeholder="Send Alert Interval"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.sendAlertInterval.errors }" />
            <div *ngIf="isValidFormSubmitted && f.sendAlertInterval.errors" class="invalid-feedback">
              <div *ngIf="f.sendAlertInterval.errors.required">Send Alert Interval is required</div>
              <div *ngIf="f.sendAlertInterval.errors.min">Send Alert Interval is 0 ~ 100</div>
              <div *ngIf="f.sendAlertInterval.errors.max">Send Alert Interval is 0 ~ 100</div>
            </div>
          </div> -->

        </div>
        <div class="card-footer">
          <button (click)="onClickEditTargetTenant()" type="submit" class="btn btn-primary">保存</button>
        </div>
      </form>
    </div>
  </div>
</section>