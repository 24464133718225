import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';

import { TenantAdminAuthGuard } from '../../../utils/guards/tenantadmin-auth.guard';

import { AccountManagementComponent } from './accountmanagement.component';
import { CreateAccountComponent } from './createaccount/createaccount.component';
import { EditAccountComponent } from './editaccount/editaccount.component';
import { DeleteAccountComponent } from './deleteaccount/deleteaccount.component';

const routes: Routes = [
    {
        path: '',
        component: AccountManagementComponent,
        canActivate: [TenantAdminAuthGuard],
    },
    {
        path: 'create',
        component: CreateAccountComponent,
        canActivate: [TenantAdminAuthGuard],
    },
    {
        path: 'edit',
        component: EditAccountComponent,
        canActivate: [TenantAdminAuthGuard],
    },
    {
        path: 'delete',
        component: DeleteAccountComponent,
        canActivate: [TenantAdminAuthGuard],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountManagementRoutingModule { }
