import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { DataStore } from '@aws-amplify/datastore';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { Tenant } from '../../../../../models';

@Component({
  selector: 'app-createtenant',
  templateUrl: './createtenant.component.html',
  styleUrls: ['./createtenant.component.scss']
})
export class CreateTenantComponent implements OnInit {
  public registerForm: FormGroup;
  isValidFormSubmitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(64)]],
      license: ['', [Validators.required, Validators.maxLength(40)]],
      domainList: ['', [Validators.maxLength(512)]],
      expirationStartDate: ['', [Validators.required]],
      expirationEndDate: ['', [Validators.required]],
      mailaddressOfAlert: ['', [Validators.required, Validators.maxLength(256)]],
      monitoringInterval: [10, [Validators.required, Validators.min(0), Validators.max(100)]],
      continuousMoveThreshold: [10, [Validators.required, Validators.min(1), Validators.max(600)]],
      sendAlertInterval: [10, [Validators.required, Validators.min(0), Validators.max(100)]],
      alertMessageRetentionPeriod: [10, [Validators.required, Validators.min(1), Validators.max(365)]],
      licenseOfEscape: [false],
      licenseOfHighFever: [false],
      licenseOfUnKnown: [false],
      licenseOfWatchOver: [false],
      licenseOfAutoUnlock: [false],
    });
  }


  onClickCreateTenant() {
    this.isValidFormSubmitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();
      let name = this.registerForm.get("name").value;
      let license = this.registerForm.get("license").value;
      let domainList = this.registerForm.get("domainList").value;
      let expirationStartDate = this.registerForm.get("expirationStartDate").value;
      let expirationEndDate = this.registerForm.get("expirationEndDate").value;
      let mailaddressOfAlert = this.registerForm.get("mailaddressOfAlert").value;
      let monitoringInterval = this.registerForm.get("monitoringInterval").value;
      let continuousMoveThreshold = this.registerForm.get("continuousMoveThreshold").value;
      let sendAlertInterval = this.registerForm.get("sendAlertInterval").value;
      let alertMessageRetentionPeriod = this.registerForm.get("alertMessageRetentionPeriod").value;
      let licenseOfEscape = false;
      if (this.registerForm.get("licenseOfEscape").value == true) {
        licenseOfEscape = true;
      }
      let licenseOfHighFever = false;
      if (this.registerForm.get("licenseOfHighFever").value == true) {
        licenseOfHighFever = true;
      }
      let licenseOfUnKnown = false;
      if (this.registerForm.get("licenseOfUnKnown").value == true) {
        licenseOfUnKnown = true;
      }
      let licenseOfWatchOver = false;
      if (this.registerForm.get("licenseOfWatchOver").value == true) {
        licenseOfWatchOver = true;
      }
      let licenseOfAutoUnlock = false;
      if (this.registerForm.get("licenseOfAutoUnlock").value == true) {
        licenseOfAutoUnlock = true;
      }
      DataStore.save(
        new Tenant({
          name: name,
          licenseCode: license,
          domainList: domainList,
          expirationStartDate: expirationStartDate,
          expirationEndDate: expirationEndDate,
          mailaddressOfAlert: mailaddressOfAlert,
          monitoringInterval: monitoringInterval,
          continuousMoveThreshold: continuousMoveThreshold,
          sendAlertInterval: sendAlertInterval,
          alertMessageRetentionPeriod: alertMessageRetentionPeriod,
          licenseOfEscape: licenseOfEscape,
          licenseOfHighFever: licenseOfHighFever,
          licenseOfUnKnown: licenseOfUnKnown,
          licenseOfWatchOver: licenseOfWatchOver,
          licenseOfAutoUnlock: licenseOfAutoUnlock,
          isSystemAdmin: false,
        })
      ).then(result => {
        this.toastr.success('保存されました。', '成功');
        this.registerForm.reset();
        this.router.navigate(['/tenantmanagement'])
      }).catch(error => {
        this.toastr.error(error.message, 'エラー');
      });
      setTimeout(() => this.spinner.hide(), 500);
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }

  }

  get f() { return this.registerForm.controls; }

  ngAfterViewInit() {
  }
}
