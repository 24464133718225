import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, from, Observable} from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { DataStore, Predicates } from '@aws-amplify/datastore';

import { Tenant } from '../../../../models';

@Component({
  selector: 'app-tenantmanagement',
  templateUrl: './tenantmanagement.component.html',
  styleUrls: ['./tenantmanagement.component.scss']
})
export class TenantManagementComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  tenants;
  editTarget;

  constructor(
    private router: Router,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.list();
    this.dtOptions = {
      search: {
        regex: false,
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.list();
        this.tenants.subscribe(resp =>{
          callback({
            data: resp,
          });  
        })
      },
      columns: [
        {
          title: 'テナント名',
          data: 'name',
          name: 'name',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: 'テナントＩＤ',
          data: 'id',
          name: 'id',
        },
        {
          title: 'ライセンスコード',
          data: 'licenseCode',
          name: 'licenseCode',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: 'ライセンス開始日時',
          data: 'expirationStartDate',
          name: 'expirationStartDate',
        },
        {
          title: 'ライセンス終了日時',
          data: 'expirationEndDate',
          name: 'expirationEndDate',
        },
        {
          title: '操作',
          orderable: false,
          render: function (data: any, type: any, full: any) {
            return '<button type="button" edit-tenant-id="' + full.id + '" class="btn btn-xs btn-info margin-r-5">編集</button>' +
              '<button type="button" delete-tenant-id="' + full.id + '" class="btn btn-xs btn-warning margin-r-5">削除</button></div>';
          }
        }
      ]
    };
  }

  list() {
    this.tenants = from(DataStore.query(Tenant, Predicates.ALL));
  }
  
  private get(id: string) {
    return from(DataStore.query(Tenant, id));
  }

  onClickCreateTenant() {
    this.router.navigate(['/tenantmanagement/create'])
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.list();
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
    this.renderer.listen('document', 'click', (event) => {
      this.tenants.subscribe(resp => {
        if (event.target.hasAttribute("edit-tenant-id")) {
          resp.forEach(element => {
            if (element.id == event.target.getAttribute("edit-tenant-id")) {
              this.get(element.id).subscribe(item => {
                this.router.navigate(['/tenantmanagement/edit'], { state: { target: item } });
              });
            }
          });
        }
        else if (event.target.hasAttribute("delete-tenant-id")) {
          resp.forEach(element => {
            if (element.id == event.target.getAttribute("delete-tenant-id")) {
              this.get(element.id).subscribe(item => {
                this.router.navigate(['/tenantmanagement/delete'], { state: { target: item } });
              });
            }
          });
        }
  
      })
    });
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }
}
