import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthService } from '../utils/services/auth.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  isValidFormSubmitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'register-page');
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ForgotPassword() {
    this.isValidFormSubmitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();
      const email = this.registerForm.get("email").value;
      this.auth.forgotPassword(email)
        .subscribe(
          result => {
            setTimeout(() => this.spinner.hide(), 500);
            this.router.navigate(['/confirmforgotpassword'], { state: { email: email } })
          },
          error => {
            console.log(error);
            setTimeout(() => this.spinner.hide(), 500);
            if (error.code = "LimitExceededException") {
              this.toastr.error('連続してパスワードリセット要求がありました。しばらくしてから再度実行してください。', 'エラー');
            } else {
              this.toastr.error('メールアドレスが間違えています。', 'エラー');
            }
          });
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }
  }

  get f() { return this.registerForm.controls; }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'register-page');
  }
}
