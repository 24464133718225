import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component';
import { ConfirmSignUpComponent } from './confirmsignup/confirmsignup.component';
import { ConfirmForgotPasswordComponent } from './confirmforgotpassword/confirmforgotpassword.component';
import { CompleteNewPasswordComponent } from './completenewpassword/completenewpassword.component';

import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { MainModule } from './main/main.module';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => MainModule,
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'signup',
    component: SignUpComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'confirmsignup',
    component: ConfirmSignUpComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'confirmforgotpassword',
    component: ConfirmForgotPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'completenewpassword',
    component: CompleteNewPasswordComponent,
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
