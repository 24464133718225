import { Injectable, NgZone } from '@angular/core';
// 追加
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { map, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { Auth, auth0SignInButton } from 'aws-amplify';
import { DataStore, Predicates } from '@aws-amplify/datastore';

@Injectable()
export class AuthService {

  public loggedIn: BehaviorSubject<boolean>;

  constructor(
    private router: Router,
    private ngzone: NgZone
  ) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  /** サインアップ */
  public signUp(email, password, attributes): Observable<any> {
    return fromPromise(Auth.signUp({
      'username': email,
      'password': password,
      'attributes': attributes
    }

    ));
  }

  /** サインアップ検証 */
  public confirmSignUp(email, code): Observable<any> {
    return fromPromise(Auth.confirmSignUp(email, code));
  }

  /** 初回サインイン時のパスワード変更 */
  public completeNewPassword(user, password, requiredAttributes): Observable<any> {
    return fromPromise(Auth.completeNewPassword(user, password, requiredAttributes));
  }

  /** ログイン */
  public signIn(email, password): Observable<any> {
    DataStore.clear();
    return fromPromise(Auth.signIn(email, password))
      .pipe(
        tap(() => this.loggedIn.next(true))
      );
  }

  /** パスワードリセット */
  public forgotPassword(email): Observable<any> {
    return fromPromise(Auth.forgotPassword(email));
  }

  /** パスワードリセット検証 */
  public forgotPasswordSubmit(email, code, password): Observable<any> {
    return fromPromise(Auth.forgotPasswordSubmit(email, code, password));
  }

  /** パスワード変更 */
  public changePassword(user, oldPassword, newPassword): Observable<any> {
    return fromPromise(Auth.changePassword(user, oldPassword, newPassword));
  }

  /** ログインメールアドレスの取得 */
  public getCurrentAuthenticatedUser(): Observable<string> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => {
          return of("");
        })
      );
  }

  /** ログインメールアドレスの取得 */
  public getLoginEmail(): Observable<string> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          return result.attributes.email;
        }),
        catchError(error => {
          return of("");
        })
      );
  }

  /** グループ名の取得 */
  public getGroupName(): Observable<string> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          return result.signInUserSession.accessToken.payload["cognito:groups"];
        }),
        catchError(error => {
          return of("");
        })
      );
  }

  /** テナントIDの取得 */
  public getTenantId(): Observable<string> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          return result.attributes["custom:tenant"];
        }),
        catchError(error => {
          return of("");
        })
      );
  }

  /** ログイン状態の取得 */
  public isAuthenticated(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          this.loggedIn.next(true);
          return true;
        }),
        catchError(error => {
          this.loggedIn.next(false);
          return of(false);
        })
      );
  }

  /** ログイン状態の取得 */
  public isUnAuthenticated(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          this.loggedIn.next(true);
          return false;
        }),
        catchError(error => {
          this.loggedIn.next(false);
          return of(true);
        })
      );
  }

  /** SystemAdminのGuardの取得 */
  public isSystemAdminGuard(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          let groupName = result.signInUserSession.accessToken.payload["cognito:groups"];
          if (groupName == "SystemAdmin") {
            return true;
          } else if (groupName == "TenantAdmin") {
            return false;
          } else if (groupName == "TenantUser") {
            return false;
          }
          return false;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  /** SystemAdmin OnlyのGuardの取得 */
  public isSystemAdminOnlyGuard(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          let groupName = result.signInUserSession.accessToken.payload["cognito:groups"];
          if (groupName == "SystemAdmin") {
            return true;
          } else if (groupName == "TenantAdmin") {
            return false;
          } else if (groupName == "TenantUser") {
            return false;
          }
          return false;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  /** TenantAdminのGuardの取得 */
  public isTenantAdminGuard(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          let groupName = result.signInUserSession.accessToken.payload["cognito:groups"];
          if (groupName == "SystemAdmin") {
            return true;
          } else if (groupName == "TenantAdmin") {
            return true;
          } else if (groupName == "TenantUser") {
            return false;
          }
          return false;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  /** TenantAdminのGuard Onlyの取得 */
  public isTenantAdminOnlyGuard(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          let groupName = result.signInUserSession.accessToken.payload["cognito:groups"];
          if (groupName == "SystemAdmin") {
            return false;
          } else if (groupName == "TenantAdmin") {
            return true;
          } else if (groupName == "TenantUser") {
            return false;
          }
          return false;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  /** TenantUserのGuardの取得 */
  public isTenantUserGuard(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          let groupName = result.signInUserSession.accessToken.payload["cognito:groups"];
          if (groupName == "SystemAdmin") {
            return true;
          } else if (groupName == "TenantAdmin") {
            return true;
          } else if (groupName == "TenantUser") {
            return true;
          }
          return false;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  /** TenantUser OnlyのGuardの取得 */
  public isTenantUserOnlyGuard(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          let groupName = result.signInUserSession.accessToken.payload["cognito:groups"];
          if (groupName == "SystemAdmin") {
            return false;
          } else if (groupName == "TenantAdmin") {
            return false;
          } else if (groupName == "TenantUser") {
            return true;
          }
          return false;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  /** ログアウト */
  public signOut() {
    fromPromise(Auth.signOut())
      .subscribe(
        result => {
          DataStore.clear();
          this.loggedIn.next(false);
          this.ngzone.runOutsideAngular(() => {
            location.reload();
          });
          this.router.navigate(['/login']);
        },
        error => console.log(error)
      );
  }
}