import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';

import { SystemAdminAuthGuard } from '../../../utils/guards/systemadmin-auth.guard';

import { TenantManagementComponent } from './tenantmanagement.component';
import { CreateTenantComponent } from './createtenant/createtenant.component';
import { EditTenantComponent } from './edittenant/edittenant.component';
import { DeleteTenantComponent } from './deletetenant/deletetenant.component';

const routes: Routes = [
    {
        path: '',
        component: TenantManagementComponent,
        canActivate: [SystemAdminAuthGuard],
    },
    {
        path: 'create',
        component: CreateTenantComponent,
        canActivate: [SystemAdminAuthGuard],
    },
    {
        path: 'edit',
        component: EditTenantComponent,
        canActivate: [SystemAdminAuthGuard],
    },
    {
        path: 'delete',
        component: DeleteTenantComponent,
        canActivate: [SystemAdminAuthGuard],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TenantManagementRoutingModule { }
