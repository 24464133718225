<li class="nav-item dropdown">
  <a class="nav-link" (click)="toggleDropdownMenu()">
    <em class="far fa-comments"></em>
    <span class="badge badge-danger navbar-badge">3</span>
  </a>
  <div #dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
    <a href="#" class="dropdown-item">
      <!-- Message Start -->
      <div class="media">
        <img
          src="assets/img/user1-128x128.jpg"
          alt="User Avatar"
          class="img-size-50 mr-3 img-circle"
        />
        <div class="media-body">
          <h3 class="dropdown-item-title">
            Brad Diesel
            <span class="float-right text-sm text-danger"
              ><em class="fas fa-star"></em></span>
          </h3>
          <p class="text-sm">Call me whenever you can...</p>
          <p class="text-sm text-muted">
            <em class="far fa-clock mr-1"></em> 4 Hours Ago
          </p>
        </div>
      </div>
      <!-- Message End -->
    </a>
    <div class="dropdown-divider"></div>
    <a href="#" class="dropdown-item">
      <!-- Message Start -->
      <div class="media">
        <img
          src="assets/img/user8-128x128.jpg"
          alt="User Avatar"
          class="img-size-50 img-circle mr-3"
        />
        <div class="media-body">
          <h3 class="dropdown-item-title">
            John Pierce
            <span class="float-right text-sm text-muted"
              ><em class="fas fa-star"></em></span>
          </h3>
          <p class="text-sm">I got your message bro</p>
          <p class="text-sm text-muted">
            <em class="far fa-clock mr-1"></em> 4 Hours Ago
          </p>
        </div>
      </div>
      <!-- Message End -->
    </a>
    <div class="dropdown-divider"></div>
    <a href="#" class="dropdown-item">
      <!-- Message Start -->
      <div class="media">
        <img
          src="assets/img/user3-128x128.jpg"
          alt="User Avatar"
          class="img-size-50 img-circle mr-3"
        />
        <div class="media-body">
          <h3 class="dropdown-item-title">
            Nora Silvester
            <span class="float-right text-sm text-warning"
              ><em class="fas fa-star"></em></span>
          </h3>
          <p class="text-sm">The subject goes here</p>
          <p class="text-sm text-muted">
            <em class="far fa-clock mr-1"></em> 4 Hours Ago
          </p>
        </div>
      </div>
      <!-- Message End -->
    </a>
    <div class="dropdown-divider"></div>
    <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
  </div>
</li>
