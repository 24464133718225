import { Component } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import { AuthService } from './utils/services/auth.service';
import { AmplifyService } from 'aws-amplify-angular';        

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Hito Terrace';
  signedIn: boolean;
  user: any;
  greeting: string;

  constructor(
    public auth: AuthService,
    private amplifyService: AmplifyService,
  ) {
    this.amplifyService.authStateChange$
      .subscribe(authState => {
        this.signedIn = authState.state === 'signedIn';
        if (!authState.user) {
          this.user = null;
        } else {
          this.user = authState.user;
          this.greeting = "Hello " + this.user.username;
        }
      });
  }
}
