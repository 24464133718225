<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6"><h1>職員管理</h1></div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header"><h3 class="card-title">職員削除</h3></div>
      <form [formGroup]="deleteForm">
        <div class="card-body">

          <div class="input-group mb-3">
            <label class="col-md-4">メールアドレス</label>
            <input type="email" formControlName="email" class="form-control" placeholder="MailAddress"/>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">所属名</label>
            <input type="text" formControlName="groupName" class="form-control" placeholder="Group Name" />
          </div>
<!--
          <div class="input-group mb-3">
            <label class="col-md-4">有効期限</label>
            <input type="date" formControlName="expirationEndDate" class="form-control" placeholder="End Date" />
          </div>
-->
          <div class="input-group mb-3">
            <label class="col-md-4">管理者</label>
            <input type="checkbox" formControlName="isTenantAdmin" class="form-control" placeholder="Tenant Admin"
              id="isTenantAdmin" />
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <button (click)="onClickDeleteAccount()" type="submit" class="btn btn-primary">削除</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>