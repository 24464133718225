import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { API } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { DataStore, syncExpression } from '@aws-amplify/datastore';

import { AppService } from '../../../../utils/services/app.service';
import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';

@Component({
  selector: 'app-deleteaccount',
  templateUrl: './deleteaccount.component.html',
  styleUrls: ['./deleteaccount.component.scss']
})
export class DeleteAccountComponent implements OnInit {
  public deleteForm: FormGroup;
  isValidFormSubmitted = null;
  deleteTarget;
  tenantId: string = "";
  subscription;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private appService: AppService,
  ) {
    try {
      this.deleteTarget = this.router.getCurrentNavigation().extras.state.target;
    }
    catch (e) {
      this.router.navigate([''])
    }
   }

  ngOnInit() {
    this.deleteForm = this.formBuilder.group({
      email: [{value: this.deleteTarget.email, disabled: true}, [Validators.required, Validators.maxLength(256)]],
      groupName: [{value: this.deleteTarget.groupName, disabled: true}, [Validators.required, Validators.maxLength(256)]],
      expirationEndDate: [{value: this.deleteTarget.expirationEndDate, disabled: true}, [Validators.required]],
      isTenantAdmin: [{value: this.deleteTarget.isTenantAdmin, disabled: true}],
    });

    this.subscription = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
      });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

  }
  
  onClickDeleteAccount() {
    this.isValidFormSubmitted = true;
    this.spinner.show();
    let userName = this.deleteTarget.userName;
    let email = this.deleteForm.get("email").value;
    let groupName = this.deleteForm.get("groupName").value;
    let expirationEndDate = this.deleteForm.get("expirationEndDate").value;
    let isTenantAdmin = false;
    if (this.deleteForm.get("isTenantAdmin").value == true) {
      isTenantAdmin = true;
    }
    const myInit = {
      body: {
        userName: userName,
        email: email,
        groupName: groupName,
        expirationEndDate: expirationEndDate,
        isTenantAdmin: isTenantAdmin,
      },
      headers: {}
    };
    API
      .del('hitoterracerestapi', '/accounts', myInit)
      .then(response => {
        if (response.body.code == 100) {
          this.toastr.error('すでに同じメールアドレスが登録されています。', 'エラー');
        } else if (response.body.code == 101) {
          this.toastr.error('該当のユーザーが存在しません。', 'エラー');
        } else {
          this.toastr.success('保存されました。', '成功');
          this.toastr.success('しばらく待ってからリストを更新してください。', '成功');
          this.deleteForm.reset();
          this.router.navigate(['/accountmanagement'])
        }
        setTimeout(() => this.spinner.hide(), 500);
    })
      .catch(error => {
        this.toastr.error(error.response, 'エラー');
        setTimeout(() => this.spinner.hide(), 500);
    });

  }

  ngAfterViewInit() {
  }
}
