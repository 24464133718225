export class TargetPersonManagementRow {
  id: string;
  name: string;
  uid: string;
  description: string;
  roomNumber: string;
  enable: boolean;
  imege1Key: string;
  imege2Key: string;
  imege3Key: string;
  imege4Key: string;
  imege5Key: string;
  image1Url: string;
  image2Url: string;
  image3Url: string;
  image4Url: string;
  image5Url: string;
  camera_type: string;
  serialNumber: string;
}