<div class="login-box">
  <div class="login-logo">
    <a href="../../index2.html"><img src="assets/img/hitoterrace_logo.png" alt="HitoTerrace Logo"></a>
  </div>
  <!-- /.login-logo -->
  <div class="card">
    <div class="card-body login-card-body">
      <p class="login-box-msg">Sign in to start your session</p>

      <form [formGroup]="loginForm">
        <div class="input-group mb-3">
          <input formControlName="email" type="text" class="form-control" placeholder="Email"
            [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.email.errors }" />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
          <div *ngIf="isValidFormSubmitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
        </div>
        <div class="input-group mb-3">
          <input formControlName="password" type="password" class="form-control" placeholder="Password"
            [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.password.errors }" />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
          <div *ngIf="isValidFormSubmitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
            <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
          </div>
          <!-- /.col -->
          <div class="col-4">
            <button (click)="logIn()" type="submit" class="btn btn-primary btn-block btn-flat">
              Sign In
            </button>
          </div>
          <!-- /.col -->
        </div>
      </form>
      <p class="mb-1">
        <a [routerLink]="['/forgotpassword']" class="text-center">I forgot my password</a>
      </p>
      <p class="mb-0">
        <a [routerLink]="['/signup']" class="text-center">Register a new membership</a>
      </p>
    </div>
    <!-- /.login-card-body -->
  </div>
</div>