<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6"><h1>職員管理</h1></div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header"><h3 class="card-title">職員編集</h3></div>
      <form [formGroup]="updateForm">
        <div class="card-body">

          <div class="input-group mb-3">
            <label class="col-md-4">メールアドレス</label>
            <input type="email" formControlName="email" class="form-control" placeholder="Mail Address"/>
          </div>

          <div class="input-group mb-3">
            <label class="col-md-4">所属名</label>
            <input type="text" formControlName="groupName" class="form-control" placeholder="Group Name"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.groupName.errors }" />
            <div *ngIf="isValidFormSubmitted && f.groupName.errors" class="invalid-feedback">
              <div *ngIf="f.groupName.errors.required">Group Name is required</div>
              <div *ngIf="f.groupName.errors.maxlength">max 256 characters</div>
              <div *ngIf="f.groupName.errors.pattern">Includes restricted strings[" ,]</div>
            </div>
          </div>
<!--
          <div class="input-group mb-3">
            <label class="col-md-4">有効期限</label>
            <input type="date" formControlName="expirationEndDate" class="form-control" placeholder="End Date"
              [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.expirationEndDate.errors }" />
            <div *ngIf="isValidFormSubmitted && f.expirationEndDate.errors" class="invalid-feedback">
              <div *ngIf="f.expirationEndDate.errors.required">End Date is required</div>
            </div>
          </div>
-->
          <div class="input-group mb-3">
            <label class="col-md-4">管理者</label>
            <input type="checkbox" formControlName="isTenantAdmin" class="form-control" placeholder="Tenant Admin" id="isTenantAdmin" />
          </div>

        </div>
        <div class="card-footer">
          <div class="row">
            <button (click)="onClickEditAccount()" type="submit" class="btn btn-primary">編集</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
