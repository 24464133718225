<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Test Alert Message</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><a href="#">Home</a></li>
          <li class="breadcrumb-item active">Test Alert Message</li>
        </ol>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
  <!-- Default box -->
  <div class="container-fluid">
    <p *ngIf="isValidFormSubmitted && registerForm.pristine" [ngClass]="'success'">
      Form submitted successfully.
    </p>
    <form [formGroup]="registerForm">

      <div class="input-group mb-3">
        <input type="text" formControlName="alertKind" class="form-control" placeholder="alertKind" />
      </div>
      <div *ngIf="alertKind.errors && isValidFormSubmitted != null && !isValidFormSubmitted">
        <div *ngIf="alertKind.errors.required">
          alertKind required.
        </div>
        <div *ngIf="alertKind.errors.pattern">
          alertKind not valid.
        </div>
      </div>

      <div class="input-group mb-3">
        <input type="text" formControlName="name" class="form-control" placeholder="name" />
      </div>
      <div *ngIf="name.errors && isValidFormSubmitted != null && !isValidFormSubmitted">
        <div *ngIf="name.errors.required">
          name required.
        </div>
        <div *ngIf="name.errors.pattern">
          name not valid.
        </div>
      </div>

      <div class="input-group mb-3">
        <input type="text" formControlName="uid" class="form-control" placeholder="uid" />
      </div>
      <div *ngIf="uid.errors && isValidFormSubmitted != null && !isValidFormSubmitted">
        <div *ngIf="uid.errors.required">
          uid required.
        </div>
        <div *ngIf="uid.errors.pattern">
          uid not valid.
        </div>
      </div>

      <div class="input-group mb-3">
        <input type="text" formControlName="content" class="form-control" placeholder="content" />
      </div>
      <div *ngIf="content.errors && isValidFormSubmitted != null && !isValidFormSubmitted">
        <div *ngIf="content.errors.required">
          content required.
        </div>
        <div *ngIf="content.errors.pattern">
          content not valid.
        </div>
      </div>

      <div class="input-group mb-3">
        <input type="text" formControlName="detail" class="form-control" placeholder="detail" />
      </div>
      <div *ngIf="detail.errors && isValidFormSubmitted != null && !isValidFormSubmitted">
        <div *ngIf="detail.errors.required">
          detail required.
        </div>
        <div *ngIf="detail.errors.pattern">
          detail not valid.
        </div>
      </div>

      <div class="input-group mb-3">
        <input type="text" formControlName="cameraId" class="form-control" placeholder="cameraId" />
      </div>
      <div *ngIf="cameraId.errors && isValidFormSubmitted != null && !isValidFormSubmitted">
        <div *ngIf="cameraId.errors.required">
          cameraId required.
        </div>
        <div *ngIf="cameraId.errors.pattern">
          cameraId not valid.
        </div>
      </div>

      <div class="input-group mb-3">
        <input type="text" formControlName="cameraDescription" class="form-control" placeholder="cameraDescription" />
      </div>
      <div *ngIf="cameraDescription.errors && isValidFormSubmitted != null && !isValidFormSubmitted">
        <div *ngIf="cameraDescription.errors.required">
          cameraDescription required.
        </div>
        <div *ngIf="cameraDescription.errors.pattern">
          cameraDescription not valid.
        </div>
      </div>

      <div class="input-group mb-3">
        <input type="text" formControlName="cameraThreshold" class="form-control" placeholder="cameraThreshold" />
      </div>
      <div *ngIf="cameraThreshold.errors && isValidFormSubmitted != null && !isValidFormSubmitted">
        <div *ngIf="cameraThreshold.errors.required">
          cameraThreshold required.
        </div>
        <div *ngIf="cameraThreshold.errors.pattern">
          cameraThreshold not valid.
        </div>
      </div>

      <div class="input-group mb-3">
        <label for="image1File">Choose Image 1 File(jpeg)</label>
        <input type="file" id="image1File" accept="image/jpeg" (change)="handleimage1FileInput($event.target.files)"> 
      </div>

      <div class="input-group mb-3">
        <label for="image2File">Choose Image 2 File(jpeg)</label>
        <input type="file" id="image2File" accept="image/jpeg" (change)="handleimage2FileInput($event.target.files)"> 
      </div>

      <div class="input-group mb-3">
        <label for="image3File">Choose Image 2 File(jpeg)</label>
        <input type="file" id="image3File" accept="image/jpeg" (change)="handleimage3FileInput($event.target.files)"> 
      </div>

      <div class="col-8">
      </div>
      <div class="col-4">
        <button (click)="onClickCreateAlertMessage()" type="submit" class="btn btn-primary btn-block btn-flat">
          Create AlertMessage
        </button>
      </div>
      <div class="col-8">
      </div>
      <div class="col-4">
        <button (click)="onClickDeleteAllAlertMessage()" type="submit" class="btn btn-warning btn-block btn-flat">
          Delete All
        </button>
      </div>
      <!-- /.col -->
    </form>

  </div>
  <!-- /.card -->
</section>
<!-- /.content -->