<div class="register-box">
  <div class="register-logo">
    <a href="../../index2.html"><img src="assets/img/hitoterrace_logo.png" alt="HitoTerrace Logo"></a>
  </div>

  <div class="card">
    <div class="card-body register-card-body">
      <p class="login-box-msg">I forgot my password</p>

      <form [formGroup]="registerForm">
        <div class="input-group mb-3">
          <input type="text" formControlName="email" class="form-control" placeholder="Email"
            [ngClass]="{ 'is-invalid': isValidFormSubmitted && f.email.errors }" />
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
          <div *ngIf="isValidFormSubmitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">Email is required</div>
            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
          </div>
          <!-- /.col -->
          <div class="col-4">
            <button (click)="ForgotPassword()" type="submit" class="btn btn-primary btn-block btn-flat">
              Send confirm code
            </button>
          </div>
          <!-- /.col -->
        </div>
      </form>
    </div>
    <!-- /.form-box -->
  </div>
  <!-- /.card -->
</div>