import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TargetPersonManagementRoutingModule } from './targetpersonmanagement-routing.module';

import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AuthService } from '../../../utils/services/auth.service';

import { TargetPersonManagementComponent } from './targetpersonmanagement.component';
import { CreateTargetPersonComponent } from './createtargetperson/createtargetperson.component';
import { EditTargetPersonComponent } from './edittargetperson/edittargetperson.component';
import { DeleteTargetPersonComponent } from './deletetargetperson/deletetargetperson.component';

@NgModule({
  declarations: [
    TargetPersonManagementComponent,
    CreateTargetPersonComponent,
    EditTargetPersonComponent,
    DeleteTargetPersonComponent
  ],
  imports: [
    CommonModule,
    TargetPersonManagementRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    AmplifyAngularModule,
    DataTablesModule,
  ],
  providers: [AuthService, AmplifyService],
})
export class TargetPersonManagementModule { }
