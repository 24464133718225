import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
//import { Component, OnInit, ViewChild, Hub } from '@angular/core';
import { Subject, from }                from 'rxjs';

import { DataTableDirective }           from 'angular-datatables';
import { AmplifyService }               from 'aws-amplify-angular';
import { API, Logger, Hub, input }             from 'aws-amplify';
import { DataStore, Predicates, syncExpression } from '@aws-amplify/datastore';

import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../models';
import { AlertMessageTenantAdminRow }   from './alertMessageTenantAdminRow';
import { AppService }                   from '../../../utils/services/app.service';
import * as moment from 'moment';

//const logger = new Logger('foo');
//const logger = new Logger('foo', 'INFO');
const logger = new Logger('atal', 'DEBUG');
//Amplify.Logger.LOG_LEVEL = 'DEBUG';
//window.LOG_LEVEL = 'DEBUG';

declare var $: any;

@Component({
  selector: 'app-alertmessagetenantadminlist',
  templateUrl: './alertmessagetenantadminlist.component.html',
  styleUrls: ['./alertmessagetenantadminlist.component.scss']
})
export class AlertMessageTenantAdminListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  alertMessages;
  editTarget;
  tenantId: string = '';
  subscription;
  subscription2;
  email: string = '';
  fromDate: Date;
  toDate: Date;
  btnDisabled: boolean = false;
  title: string;
  fileName: string;

  constructor(
    private amplifyService: AmplifyService,
    private appService: AppService,
  ) { }

  ngOnInit() {
  // try {
    // this.observe();
    this.subscription = this.appService.getTenantId().subscribe(result => {
      this.tenantId = result;
      // logger.debug('Login_User_tenantId: ', this.tenantId);

      DataStore.configure({
        maxRecordsToSync: 1000000,
        syncExpressions: [
          syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
          //syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId)),
          syncExpression(AlertMessage, () => {
            return (c) => c.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted');
          }),
          syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
          syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
          syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
          syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
          syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
        ]
      });

      this.defaultList();
    });

    //日付範囲指定フィルターの適用
    this.filterByFromTo();

    // 出力ボタンのタイトル、ファイル名の取得
    this.getTitle();
    this.getFileName();

    this.dtOptions = {
      search: {
        regex: false,
      },
      scrollX: true,
      scrollCollapse: true,
      order: [[6,'desc']],
      // stateSave: true,
      lengthMenu: [10, 25, 50, 100],
      // columnDefs: [
      //   { targets: 0, width: 100 }, // 種別
      //   { targets: 1, width: 100 }, // 氏名
      //   { targets: 2, width: 100 }, // uid
      //   { targets: 3, width: 100 }, // インフォメーション
      //   { targets: 4, width: 100 }, // 詳細
      //   { targets: 5, width: 100 }, // カメラ情報
      //   { targets: 6, width: 100 }, // 発生日時
      //   { targets: 7, width: 250 }, // マスター画像
      //   { targets: 8, width: 250 }, // 検知画像(トリミング)
      //   { targets: 9, width: 250 }, // 検知画像(全体)
      // ],
      ajax: (dataTablesParameters: any, callback) => {
        let dataList = []

        this.alertMessages.subscribe(async alertMessages => {
          for (var i = 0; i < alertMessages.length; i++) {
            let alertMessageTenantAdminRow = new AlertMessageTenantAdminRow();
            /*
            logger.debug('alertMessages[' + i + '].id: ', alertMessages[i].id);
            logger.debug('alertMessages[' + i + '].alertKind: ', alertMessages[i].alertKind);
            logger.debug('alertMessages[' + i + '].name: ', alertMessages[i].name);
            logger.debug('alertMessages[' + i + '].tenantId: ', alertMessages[i].tenantId);
            logger.debug('alertMessages[' + i + '].content: ', alertMessages[i].content);
            logger.debug('alertMessages[' + i + '].detail: ', alertMessages[i].detail);
            logger.debug('alertMessages[' + i + '].cameraId: ', alertMessages[i].cameraId);
            logger.debug('alertMessages[' + i + '].cameraDescription: ', alertMessages[i].cameraDescription);
            logger.debug('alertMessages[' + i + '].cameraThreshold: ', alertMessages[i].cameraThreshold);
            logger.debug('alertMessages[' + i + '].shootingDataTime ', alertMessages[i].shootingDataTime);

            logger.debug('alertMessages[' + i + '].imege1Key: ', alertMessages[i].imege1Key);
            logger.debug('alertMessages[' + i + '].imege2Key: ', alertMessages[i].imege2Key);
            logger.debug('alertMessages[' + i + '].imege3Key: ', alertMessages[i].imege3Key);
            logger.debug('alertMessages[' + i + '].imege4Key: ', alertMessages[i].imege4Key);
            logger.debug('alertMessages[' + i + '].thumbnail1Key: ', alertMessages[i].thumbnail1Key);
            logger.debug('alertMessages[' + i + '].thumbnail2Key: ', alertMessages[i].thumbnail2Key);
            logger.debug('alertMessages[' + i + '].thumbnail3Key: ', alertMessages[i].thumbnail3Key);
            logger.debug('alertMessages[' + i + '].thumbnail4Key: ', alertMessages[i].thumbnail4Key);
            */
            alertMessageTenantAdminRow.id                = alertMessages[i].id;
            alertMessageTenantAdminRow.alertKind         = alertMessages[i].alertKind;
            alertMessageTenantAdminRow.name              = alertMessages[i].name;
            alertMessageTenantAdminRow.uid               = alertMessages[i].uid;
            alertMessageTenantAdminRow.content           = alertMessages[i].content;
            alertMessageTenantAdminRow.detail            = alertMessages[i].detail;
            alertMessageTenantAdminRow.cameraId          = alertMessages[i].cameraId;
            alertMessageTenantAdminRow.cameraDescription = alertMessages[i].cameraDescription;
            alertMessageTenantAdminRow.cameraThreshold   = alertMessages[i].cameraThreshold;
            alertMessageTenantAdminRow.shootingDataTime  = alertMessages[i].shootingDataTime;
            alertMessageTenantAdminRow.imege1Key         = alertMessages[i].imege1Key;
            alertMessageTenantAdminRow.imege2Key         = alertMessages[i].imege2Key;
            alertMessageTenantAdminRow.imege3Key         = alertMessages[i].imege3Key;
            alertMessageTenantAdminRow.imege4Key         = alertMessages[i].imege4Key;

            if (alertMessages[i].thumbnail1Key != '') {
            // if (alertMessages[i].thumbnail1Key != '' || alertMessages[i].thumbnail1Key != null) {
              alertMessageTenantAdminRow.image1Url = await this.amplifyService.storage().get(alertMessages[i].thumbnail1Key, { level: 'public' });
            } else {
              alertMessageTenantAdminRow.image1Url = '';
            }

            if (alertMessages[i].thumbnail2Key != '') {
            // if (alertMessages[i].thumbnail2Key != '' || alertMessages[i].thumbnail2Key != null) {
              alertMessageTenantAdminRow.image2Url = await this.amplifyService.storage().get(alertMessages[i].thumbnail2Key, { level: 'public' });
            } else {
              alertMessageTenantAdminRow.image2Url = '';
            }

            if (alertMessages[i].thumbnail3Key != '') {
            // if (alertMessages[i].thumbnail3Key != '' || alertMessages[i].thumbnail3Key != null) {
              alertMessageTenantAdminRow.image3Url = await this.amplifyService.storage().get(alertMessages[i].thumbnail3Key, { level: 'public' });
            } else {
              alertMessageTenantAdminRow.image3Url = '';
            }

            if (alertMessages[i].thumbnail4Key != '') {
            // if (alertMessages[i].thumbnail4Key != '' || alertMessages[i].thumbnail4Key != null) {
              alertMessageTenantAdminRow.image4Url = await this.amplifyService.storage().get(alertMessages[i].thumbnail4Key, { level: 'public' });
            } else {
              alertMessageTenantAdminRow.image4Url = '';
            }

            alertMessageTenantAdminRow.alertStatus = alertMessages[i].alertMessageStatus.alertStatus;

            dataList.push(alertMessageTenantAdminRow);
          }
          callback({
            data: dataList,
          });
          // logger.debug('callback data', dataList);
        })
      },
      columns: [
        {
          title: '種別',
          // data: 'alertKind',
          name: 'alertKind',
          render: function (data: any, type: any, full: any) {
            if (full.alertKind == 'HighFever') {
              return '<Text>検温</Text>';
            } else if (full.alertKind == 'Escape') {
              return '<Text>離院</Text>';
            } else if (full.alertKind == 'WatchOver') {
              return '<Text>院内</Text>';
            } else if (full.alertKind == 'AutoUnlock') {
              return '<Text>解錠</Text>';
            } else {
              return '<Text>不侵</Text>';
            }
          }
        },
        {
          title: '氏名',
          data: 'name',
          name: 'name',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: 'uid',
          data: 'uid',
          name: 'uid',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: 'インフォメーション',
          data: 'content',
          name: 'content',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: '詳細',
          data: 'detail',
          name: 'detail',
          render: $.fn.dataTable.render.text(),
        },
        /*
        {
          title: 'カメラ識別ID',
          data: 'cameraId',
          name: 'cameraId',
        },
        */
        {
          title: 'カメラ情報',
          data: 'cameraDescription',
          name: 'cameraDescription',
          render: $.fn.dataTable.render.text(),
        },
        /*
        {
          title: 'カメラ閾値',
          data: 'cameraThreshold',
          name: 'cameraThreshold',
        },
        */
        {
          title: '発生日時',
          data: 'shootingDataTime',
          name: 'shootingDataTime',
          render: $.fn.dataTable.render.text(),
        },
        {
          title: 'マスター画像',
          name: 'image1',
          render: function (data: any, type: any, full: any) {
            if (full.image1Url == '') {
              return '<Text>写真なし</Text>';
            } else {
              return '<img src="' + full.image1Url + '">';
            }
          }
        },
        {
          title: '検知画像（トリミング）',
          name: 'image2',
          render: function (data: any, type: any, full: any) {
            if (full.image2Url == '') {
              return '<Text>写真なし</Text>';
            } else {
              return '<img src="' + full.image2Url + '">';
            }
          }
        },
        {
          title: '検知画像（全体）',
          name: 'image3',
          render: function (data: any, type: any, full: any) {
            if (full.image3Url == '') {
              return '<Text>写真なし</Text>';
            } else {
              return '<img src="' + full.image3Url + '">';
            }
          }
        },
        /*
        {
          title: '写真4',
          name: 'image4',
          render: function (data: any, type: any, full: any) {
            if (full.image4Url == '') {
              return '<Text>写真なし</Text>';
            } else {
              return '<img src="' + full.image4Url + '">';
            }
          }
        },
        */
        {
          title: 'ステータス',
          // data: 'alertStatus',
          name: 'alertStatus',
          render: function (data: any, type: any, full: any) {
            // 発熱者以外は、ハイフンを表示（対処不要なため）
            if (full.alertKind === 'HighFever' && full.content.lastIndexOf('！') === -1) {
              return '<Text>―</Text>';
            } else {
              return full.alertStatus;
            }
          }
        }
      ],
      dom: "<'row'<'col-sm-1 left'l><'col-sm-2'B><'col-sm-9 right'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-6 left'i><'col-sm-6 right'p>>",
      buttons: [
        // {
        //   text: 'Copy',
        //   extend: 'copy',
        //   title: this.title,
        //   fieldSeparator: ',',
        // },
        {
          text: 'Print',
          extend: 'print',
          title: this.title, // ファイル内の先頭タイトル
          exportOptions: {
            stripHtml : false,
            columns: [0, 1, 2, 4, 5, 6, 7, 8, 9]
          }
        },
        {
          text: 'Excel',
          extend: 'excel',
          title: this.title, // ファイル内の先頭タイトル
          filename: this.fileName,
          charset: 'UTF-8',
          bom: true,
        },
        {
          text: 'CSV',
          extend: 'csv',
          // title: this.title, // CSVでは使用不可
          filename: this.fileName,
          charset: 'UTF-8',
          fieldSeparator: ',',
          extension: '.csv',
          bom: true,
        }
      ]
    };

    $('.dateFrom').on( 'change', function () {
      this.fromDate = $(this).val();
      // console.log('From: ' + $('.dateFrom').val());
      // console.log('To: ' + $('.dateTo').val());
    });

    $('.dateTo').on( 'change', function () {
      // console.log('From: ' + $('.dateFrom').val());
      this.toDate = $(this).val();
      // console.log('To: ' + $('.dateTo').val());
    });
  }

  /*
  async observe() {
    let alertMessages = [];
    await DataStore.observe(AlertMessage).subscribe(({ element, ...x }) => {
      alertMessages.push(element);
    });

    const listener = Hub.listen("datastore", (capsule) => {
      const {
        payload: { event, data },
      } = capsule;

      console.log("DataStore event", event, data);

      // DataStoreの同期が完了したとき、アラートメッセージ件数が2000件を満たしていない場合、リロードする
      if (event === "ready" && alertMessages.length !== 2000) {
        console.log("DataStoreの同期が完了しました");
        this.rerender()
      }
    });
  }
  */

  list(limit){
    logger.debug('list: ', limit);
    this.alertMessages = from(DataStore.query(AlertMessage, Predicates.ALL, {
      sort: s => s.shootingDataTime("DESCENDING"),
      page: 0,
      limit: limit
    }));
  }

  defaultList(){
    const todayUnixMs = Date.now(); // ex.1614524400123
    const todayUnix = Math.floor(todayUnixMs / 1000); // ex.1614524400(2021-03-01 00:00:00) 3rd 1614697200
    const twentyFourHoursAgo = todayUnix - 86400

    this.alertMessages = from(DataStore.query(AlertMessage, c => c.createDateTime("between", [twentyFourHoursAgo, todayUnix]), {
        sort: s => s.shootingDataTime("DESCENDING"),
      }));
    this.bun1Day();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //const limit = 0;
      //this.list(limit)
      this.defaultList();
      dtInstance.destroy();
      this.dtTrigger.next();
    });
    this.bun1Day();
  }

  clear(){
    $('.clearValue').val("").datepicker("update");
  }

  list1Day(){
    this.filterDate("1Day");
  }

  list3Days(){
    this.filterDate("3Days");
  }

  list1Week(){
    this.filterDate("1Week");
  }

  list1Month(){
    this.filterDate("1Month");
  }

  list3Months() {
    this.filterDate("3Months");
  }

  list6Months() {
    this.filterDate("6Months");
  }

  listFull() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.alertMessages = from(DataStore.query(AlertMessage, Predicates.ALL, {
        sort: s => s.shootingDataTime("DESCENDING"),
        page: 0,
        limit: 0
      }));
      dtInstance.destroy();
      this.dtTrigger.next();
      this.setIndividualColums();
    });
  }

  filterDate(period){
    const todayUnixMs = Date.now(); // ex.1614524400123
    const todayUnix = Math.floor(todayUnixMs / 1000); // ex.1614524400(2021-03-01 00:00:00) 3rd 1614697200
    const twentyFourHoursAgo = todayUnix - 86400
    const threeDaysAgo = todayUnix - 259200;
    const sevenDaysAgo = todayUnix - 604800;
    const thirtyOneDaysAgo = todayUnix - 2678400;
    const ninetyDaysAgo = todayUnix - 7776000;
    const oneEightyDaysAgo = todayUnix - 15552000;

    switch (period) {
      case "1Day":
        this.getDate(twentyFourHoursAgo, todayUnix);
        break;
      case "3Days":
        this.getDate(threeDaysAgo, todayUnix);
        break;
      case "1Week":
        this.getDate(sevenDaysAgo, todayUnix);
        break;
      case "1Month":
        this.getDate(thirtyOneDaysAgo, todayUnix);
        break;
      case "3Months":
        this.getDate(ninetyDaysAgo, todayUnix);
        break;
      case "6Months":
        this.getDate(oneEightyDaysAgo, todayUnix);
        break;
      default:
        this.rerender();
    };
  };

  getDate(startDate, endDate){
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.alertMessages = from(DataStore.query(AlertMessage, c => c.createDateTime("between", [startDate, endDate]), {
        sort: s => s.shootingDataTime("DESCENDING"),
      }));
      dtInstance.destroy();
      this.dtTrigger.next();
      this.setIndividualColums();
    });
  }

  getTitle(){
    let title = 'Hito Terrace アラート履歴（全体）'
    this.title = title
  }

  getFileName(){
    let today = new Date();
    let mn = today.getMonth()+1;
    let months = this.toDoubleDigits(mn);
    let d = today.getDate();
    let days = this.toDoubleDigits(d);
    let h = today.getHours();
    let hours = this.toDoubleDigits(h);
    let m = today.getMinutes();
    let minutes = this.toDoubleDigits(m);
    let s = today.getSeconds();
    let seconds = this.toDoubleDigits(s);
    let printDate = '_' + today.getFullYear() + months + days + '_' +
                      + hours + minutes + seconds; // _20210924_143109
    let fileName = 'アラート履歴（全体）' + printDate
    this.fileName = fileName
  }

  toDoubleDigits(number){
    if (number < 10) {
      let num = '0' + number.toString();
      return num;
    }else{
      return number;
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.setIndividualColums();
  }

  setIndividualColums(){
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input', this.footer()).on('keyup change', function () {
          if (that.search() !== this['value']) {
            that
              .search(this['value'])
              .draw();
          }
        });
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.dtTrigger.unsubscribe();
    $.fn['dataTable'].ext.search.pop();
  }

  filterByFromTo(){
    // console.log('fromDate: ' + $('.dateFrom').val());
    // console.log('toDate: ' + $('.dateTo').val());
    $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {

      // 発生日時を取得して、momentの形式に置き換える
      // 7列目の要素なので、6を指定します。
      let target = moment(data[6]);
      // console.log('target: ' + target);

      // 検索フィールドの要素を取得して、momentの形式に置き換える。
      let start = $('.dateFrom').val()
        ? moment($('.dateFrom').val())
        : null
      let end = $('.dateTo').val()
        ? moment($('.dateTo').val()).endOf('day')
        : null
      // console.log('start: ' + start);
      // console.log('end: ' + end);

      // 日付を比較して条件に合えばtrueを返します。
      // trueを返す事で検索結果に表示する。
      if(
        (! start && ! end) ||
        (start && end && target.isSameOrAfter(start) && target.isSameOrBefore(end)) ||
        (!start && end && target.isSameOrBefore(end)) ||
        (start && !end && target.isSameOrAfter(start))
      ){
        // console.log('true');
        return true;
      }else if(start && end && (end < start)){
        alert('日付の範囲指定に誤りがあります。To は Fromより前の日付を指定することはできません。');
        this.clear();
      }else{
        // console.log('false');
        return false;
      }
    });
  }

  filterView(){
    this.filterByFromTo();
    this.dtInstanceDraw();
  }

  bun1Day(){
    let value = $("#period1d").prop('value')
    this.bun(value)
  }

  bun3Days(){
    let value = $("#period3d").prop('value')
    this.bun(value)
  }

  bun1Week(){
    let value = $("#period1w").prop('value')
    this.bun(value)
  }

  bun1Month(){
    let value = $("#period1m").prop('value')
    this.bun(value)
  }

  bun3Months(){
    let value = $("#period3m").prop('value')
    this.bun(value)
  }

  bun6Months(){
    let value = $("#period6m").prop('value')
    this.bun(value)
  }

  bunFull(){
    let value = $("#periodfull").prop('value')
    this.bun(value)
  }

  bun(value){
    switch (value) {
      case "1day":
        $("#period1d").prop('disabled', true)
        $("#period3d").prop('disabled', false)
        $("#period1w").prop('disabled', false)
        $("#period1m").prop('disabled', false)
        $("#period3m").prop('disabled', false)
        $("#period6m").prop('disabled', false)
        $("#periodfull").prop('disabled', false)
        break;
      case "full":
        $("#period1d").prop('disabled', false)
        $("#period3d").prop('disabled', false)
        $("#period1w").prop('disabled', false)
        $("#period1m").prop('disabled', false)
        $("#period3m").prop('disabled', false)
        $("#period6m").prop('disabled', false)
        $("#periodfull").prop('disabled', true)
        break;
      case "3days":
        $("#period1d").prop('disabled', false)
        $("#period3d").prop('disabled', true)
        $("#period1w").prop('disabled', false)
        $("#period1m").prop('disabled', false)
        $("#period3m").prop('disabled', false)
        $("#period6m").prop('disabled', false)
        $("#periodfull").prop('disabled', false)
        break;
      case "1week":
        $("#period1d").prop('disabled', false)
        $("#period3d").prop('disabled', false)
        $("#period1w").prop('disabled', true)
        $("#period1m").prop('disabled', false)
        $("#period3m").prop('disabled', false)
        $("#period6m").prop('disabled', false)
        $("#periodfull").prop('disabled', false)
        break;
      case "1month":
        $("#period1d").prop('disabled', false)
        $("#period3d").prop('disabled', false)
        $("#period1w").prop('disabled', false)
        $("#period1m").prop('disabled', true)
        $("#period3m").prop('disabled', false)
        $("#period6m").prop('disabled', false)
        $("#periodfull").prop('disabled', false)
        break;
      case "3months":
        $("#period1d").prop('disabled', false)
        $("#period3d").prop('disabled', false)
        $("#period1w").prop('disabled', false)
        $("#period1m").prop('disabled', false)
        $("#period3m").prop('disabled', true)
        $("#period6m").prop('disabled', false)
        $("#periodfull").prop('disabled', false)
        break;
      case "6months":
        $("#period1d").prop('disabled', false)
        $("#period3d").prop('disabled', false)
        $("#period1w").prop('disabled', false)
        $("#period1m").prop('disabled', false)
        $("#period3m").prop('disabled', false)
        $("#period6m").prop('disabled', true)
        $("#periodfull").prop('disabled', false)
        break;
    };
  }

  dtInstanceDraw(){
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) =>{
      dtInstance.draw();
    });
  }

}