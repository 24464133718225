import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';

import { SystemAdminAuthGuard } from '../../../utils/guards/systemadmin-auth.guard';


import { TestAlertMessageComponent } from './testalertmessage.component';

const routes: Routes = [
    {
        path: '',
        component: TestAlertMessageComponent,
        canActivate: [SystemAdminAuthGuard],
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TestAlertMessageRoutingModule { }
