import { NgModule } from '@angular/core';
import {
    Routes,
    RouterModule
} from '@angular/router';

import { TenantAdminAuthGuard } from '../../../utils/guards/tenantadmin-auth.guard';

import { AlertSettingManagementComponent } from './alertsettingmanagement.component';
import { CreateAlertSettingComponent } from './createalertsetting/createalertsetting.component';
import { EditAlertSettingComponent } from './editalertsetting/editalertsetting.component';
import { DeleteAlertSettingComponent } from './deletealertsetting/deletealertsetting.component';

const routes: Routes = [
    {
        path: '',
        component: AlertSettingManagementComponent,
        canActivate: [TenantAdminAuthGuard],
    },
    {
        path: 'create',
        component: CreateAlertSettingComponent,
        canActivate: [TenantAdminAuthGuard],
    },
    {
        path: 'edit',
        component: EditAlertSettingComponent,
        canActivate: [TenantAdminAuthGuard],
    },
    {
        path: 'delete',
        component: DeleteAlertSettingComponent,
        canActivate: [TenantAdminAuthGuard],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlertSettingManagementRoutingModule { }
