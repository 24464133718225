// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Tenant, AlertMessage, AlertMessagePrivate, AlertMessageStatus, AccountMaster, TargetPersonMaster, AlertSettingMaster } = initSchema(schema);

export {
  Tenant,
  AlertMessage,
  AlertMessagePrivate,
  AlertMessageStatus,
  AccountMaster,
  TargetPersonMaster,
  AlertSettingMaster
};