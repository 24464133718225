<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>アラート履歴(システム全体)</h1>
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="card">

      <div class="card-header">
        <h3 class="card-title">アラート履歴リスト<button type="button" (click)="rerender()" class="btn btn-default btn-sm">
          <i class="fas fa-sync-alt"></i></button>
        </h3>
        <div class="card-title">
          <div class="row">
            <button (click)="list1day()" type="button" class="btn btn-secondary">1日</button>
          </div>
        </div>
        <div class="card-title">
          <div class="row">
            <button (click)="list3Days()" type="button" class="btn btn-secondary">3日</button>
          </div>
        </div>
        <div class="card-title">
          <div class="row">
            <button (click)="list1Week()" type="button" class="btn btn-secondary">1週</button>
          </div>
        </div>
        <div class="card-title">
          <div class="row">
            <button (click)="list1Month()" type="button" class="btn btn-secondary">1ヶ月</button>
          </div>
        </div>
        <div class="card-title">
          <div class="row">
            <button (click)="list3Months()" type="button" class="btn btn-secondary">3ヶ月</button>
          </div>
        </div>
        <div class="card-title">
          <div class="row">
            <button (click)="list6Months()" type="button" class="btn btn-secondary">6ヶ月</button>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="table-responsive">
          <table summary="Account List" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-striped table-bordered dt-responsive nowrap hover" style="width:100%">
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
