import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';

import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AppService } from '../../../../utils/services/app.service';
import { AuthService } from '../../../../utils/services/auth.service';
import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';

@Component({
  selector: 'app-createalertsetting',
  templateUrl: './createalertsetting.component.html',
  styleUrls: ['./createalertsetting.component.scss']
})
export class CreateAlertSettingComponent implements OnInit {
  public registerForm: FormGroup;
  isValidFormSubmitted = null;
  tenantId: string = "";
  subscription1;
  subscription2;
  accounts;
  alertSettings;
  existsHikAlert;
  loginEmail;
  escapeTypes = [
    { name: 'in', value: 'in' },
    { name: 'out', value: 'out' },
    { name: 'both', value: 'both' },
  ];
  mailDuplicated: boolean = false;
  notification1UserName: string = "";
  notification2UserName: string = "";
  notification3UserName: string = "";
  notification4UserName: string = "";
  notification5UserName: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private appService: AppService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
  ) {
    this.auth.getLoginEmail().subscribe(result => {
      this.loginEmail = result;
    });

    /*
    // Hikvision専用アラート設定を取得する
    let alertSetting = from(DataStore.query(AlertSettingMaster, c => c.tenantId("eq", this.tenantId)));
    alertSetting.subscribe(resp => {
      this.alertSettings = resp;
      this.alertSettings.map((element, index) => {
        // console.log('isHikvision', element.isHikvision)
        if (element.isHikvision) {
          this.existsHikAlert = true;
        }
      });
    })
    */
  }

  ngOnInit() {
    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

    this.subscription1 = this.appService.getTenantId().subscribe(result => {
      this.tenantId = result;
      this.listAccountMaster();
    });

    this.subscription2 = DataStore.observe(AccountMaster).subscribe(msg => {
      this.listAccountMaster();
    });

    let regex = /^(?=.*hitoterrace[0-9]{9}@apps.hitoterrace.jp).*$/;
    let isAuthCecMaintenance = this.loginEmail.match(regex);

    // ログインユーザーがCEC保守管理者の場合は、HIK専用を作成可能にする
    if (isAuthCecMaintenance) {
      this.registerForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(256), Validators.pattern('[^",]+')]],
        description: ['', [Validators.required, Validators.maxLength(300), Validators.pattern('[^",]+')]],
        notification1UserName: [''],
        notification2UserName: [''],
        notification3UserName: [''],
        notification4UserName: [''],
        notification5UserName: [''],
        isAlertEscape: [false],
        isAlertHighFever: [false],
        isAlertUnKnown: [false],
        escapeType: [''],
        highFeverThreshold: [''],
        mondayStartTime: [''],
        mondayEndTime: [''],
        tuesdayStartTime: [''],
        tuesdayEndTime: [''],
        wednesdayStartTime: [''],
        wednesdayEndTime: [''],
        thursdayStartTime: [''],
        thursdayEndTime: [''],
        fridayStartTime: [''],
        fridayEndTime: [''],
        saturdayStartTime: [''],
        saturdayEndTime: [''],
        sundayStartTime: [''],
        sundayEndTime: [''],
        isUnknownTarget: [false],
        isHikvision: [false],
        isWatchOver: [false],
        isAutoUnlock: [false],
      });
      /*
      if (this.existsHikAlert) {
        this.registerForm = this.formBuilder.group({
          name: ['', [Validators.required, Validators.maxLength(256)]],
          description: ['', [Validators.required, Validators.maxLength(300)]],
          notification1UserName: [''],
          notification2UserName: [''],
          notification3UserName: [''],
          notification4UserName: [''],
          notification5UserName: [''],
          isAlertEscape: [false],
          isAlertHighFever: [false],
          isAlertUnKnown: [false],
          escapeType: [''],
          highFeverThreshold: [''],
          mondayStartTime: [''],
          mondayEndTime: [''],
          tuesdayStartTime: [''],
          tuesdayEndTime: [''],
          wednesdayStartTime: [''],
          wednesdayEndTime: [''],
          thursdayStartTime: [''],
          thursdayEndTime: [''],
          fridayStartTime: [''],
          fridayEndTime: [''],
          saturdayStartTime: [''],
          saturdayEndTime: [''],
          sundayStartTime: [''],
          sundayEndTime: [''],
          isUnknownTarget: [false],
          isHikvision: [{value: false, disabled: true}],
          isWatchOver: [false],
          isAutoUnlock: [false],
        });
      } else {
        this.registerForm = this.formBuilder.group({
          name: ['', [Validators.required, Validators.maxLength(256)]],
          description: ['', [Validators.required, Validators.maxLength(300)]],
          notification1UserName: [''],
          notification2UserName: [''],
          notification3UserName: [''],
          notification4UserName: [''],
          notification5UserName: [''],
          isAlertEscape: [false],
          isAlertHighFever: [false],
          isAlertUnKnown: [false],
          escapeType: [''],
          highFeverThreshold: [''],
          mondayStartTime: [''],
          mondayEndTime: [''],
          tuesdayStartTime: [''],
          tuesdayEndTime: [''],
          wednesdayStartTime: [''],
          wednesdayEndTime: [''],
          thursdayStartTime: [''],
          thursdayEndTime: [''],
          fridayStartTime: [''],
          fridayEndTime: [''],
          saturdayStartTime: [''],
          saturdayEndTime: [''],
          sundayStartTime: [''],
          sundayEndTime: [''],
          isUnknownTarget: [false],
          isHikvision: [false],
          isWatchOver: [false],
          isAutoUnlock: [false],
        });
      }
      */
    } else {
      // ログインユーザーが保守管理者以外の場合は、HIK専用をdisabledにする
      this.registerForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(256), Validators.pattern('[^",]+')]],
        description: ['', [Validators.required, Validators.maxLength(300), Validators.pattern('[^",]+')]],
        notification1UserName: [''],
        notification2UserName: [''],
        notification3UserName: [''],
        notification4UserName: [''],
        notification5UserName: [''],
        isAlertEscape: [false],
        isAlertHighFever: [false],
        isAlertUnKnown: [false],
        escapeType: [''],
        highFeverThreshold: [''],
        mondayStartTime: [''],
        mondayEndTime: [''],
        tuesdayStartTime: [''],
        tuesdayEndTime: [''],
        wednesdayStartTime: [''],
        wednesdayEndTime: [''],
        thursdayStartTime: [''],
        thursdayEndTime: [''],
        fridayStartTime: [''],
        fridayEndTime: [''],
        saturdayStartTime: [''],
        saturdayEndTime: [''],
        sundayStartTime: [''],
        sundayEndTime: [''],
        isUnknownTarget: [false],
        isHikvision:  [{value: false, disabled: true}],
        isWatchOver: [false],
        isAutoUnlock: [false],
      });
    }
  }

  listAccountMaster() {
    let accountssub = from(DataStore.query(AccountMaster, c => c.tenantId("eq", this.tenantId)));
    accountssub.subscribe(resp => {
      this.accounts = [{ userName: '', email: '選択なし' }];
      this.accounts = this.accounts.concat(resp);

      this.accounts.map((element, index) => {
        let regex = /^(?=.*hitoterrace[0-9]{9}@apps.hitoterrace.jp).*$/;
        let match = element.email.match(regex);
        if (match) {
          // CECメンテナンスアカウントなので表示しない
          this.accounts.splice(index, 1)
        }
      });
    })
  }

  onClickCreateAlertSetting() {
    this.isValidFormSubmitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();
      let name = this.registerForm.get("name").value;
      let description = this.registerForm.get("description").value;
      this.notification1UserName = this.registerForm.get("notification1UserName").value;
      this.notification2UserName = this.registerForm.get("notification2UserName").value;
      this.notification3UserName = this.registerForm.get("notification3UserName").value;
      this.notification4UserName = this.registerForm.get("notification4UserName").value;
      this.notification5UserName = this.registerForm.get("notification5UserName").value;
      let isAlertEscape = false;
      if (this.registerForm.get("isAlertEscape").value == true) {
        isAlertEscape = true;
      }
      let isAlertHighFever = false;
      if (this.registerForm.get("isAlertHighFever").value == true) {
        isAlertHighFever = true;
      }
      let isAlertUnKnown = false;
      if (this.registerForm.get("isAlertUnKnown").value == true) {
        isAlertUnKnown = true;
      }
      //let escapeType = this.registerForm.get("escapeType").value;
      let escapeType = "both";
      let highFeverThreshold = "";
      if (this.registerForm.get("highFeverThreshold").value != null) {
        highFeverThreshold = this.registerForm.get("highFeverThreshold").value.toString();
      }
      let mondayStartTime = this.registerForm.get("mondayStartTime").value;
      let mondayEndTime = this.registerForm.get("mondayEndTime").value;
      let tuesdayStartTime = this.registerForm.get("tuesdayStartTime").value;
      let tuesdayEndTime = this.registerForm.get("tuesdayEndTime").value;
      let wednesdayStartTime = this.registerForm.get("wednesdayStartTime").value;
      let wednesdayEndTime = this.registerForm.get("wednesdayEndTime").value;
      let thursdayStartTime = this.registerForm.get("thursdayStartTime").value;
      let thursdayEndTime = this.registerForm.get("thursdayEndTime").value;
      let fridayStartTime = this.registerForm.get("fridayStartTime").value;
      let fridayEndTime = this.registerForm.get("fridayEndTime").value;
      let saturdayStartTime = this.registerForm.get("saturdayStartTime").value;
      let saturdayEndTime = this.registerForm.get("saturdayEndTime").value;
      let sundayStartTime = this.registerForm.get("sundayStartTime").value;
      let sundayEndTime = this.registerForm.get("sundayEndTime").value;
      let isUnknownTarget = false;
      if (this.registerForm.get("isUnknownTarget").value == true) {
        isUnknownTarget = true;
      }
      let isHikvision = false;
      if (this.registerForm.get("isHikvision").value == true) {
        isHikvision = true;
      }
      let isWatchOver = false;
      if (this.registerForm.get("isWatchOver").value == true) {
        isWatchOver = true;
      }
      let isAutoUnlock = false;
      if (this.registerForm.get("isAutoUnlock").value == true) {
        isAutoUnlock = true;
      }

      //メール送信先の重複チェック
      let mails = [];
      if(this.notification1UserName !== '' || this.notification1UserName){
        mails.push(this.notification1UserName);
      }
      if(this.notification2UserName !== '' || this.notification2UserName){
        mails.push(this.notification2UserName);
      }
      if(this.notification3UserName !== '' || this.notification3UserName){
        mails.push(this.notification3UserName);
      }
      if(this.notification4UserName !== '' || this.notification4UserName){
        mails.push(this.notification4UserName);
      }
      if(this.notification5UserName !== '' || this.notification5UserName){
        mails.push(this.notification5UserName);
      }

      let setMails = new Set(mails);
      if(setMails.size !== mails.length){
        this.toastr.error('メールアドレスが重複しています。', 'エラー');
        this.mailDuplicated = true;
      }else{
        DataStore.save(
          new AlertSettingMaster({
            tenantId: this.tenantId,
            name: name,
            description: description,
            notification1UserName: this.notification1UserName,
            notification2UserName: this.notification2UserName,
            notification3UserName: this.notification3UserName,
            notification4UserName: this.notification4UserName,
            notification5UserName: this.notification5UserName,
            isAlertEscape: isAlertEscape,
            isAlertHighFever: isAlertHighFever,
            isAlertUnKnown: isAlertUnKnown,
            escapeType: escapeType,
            highFeverThreshold: highFeverThreshold,
            mondayStartTime: mondayStartTime,
            mondayEndTime: mondayEndTime,
            tuesdayStartTime: tuesdayStartTime,
            tuesdayEndTime: tuesdayEndTime,
            wednesdayStartTime: wednesdayStartTime,
            wednesdayEndTime: wednesdayEndTime,
            thursdayStartTime: thursdayStartTime,
            thursdayEndTime: thursdayEndTime,
            fridayStartTime: fridayStartTime,
            fridayEndTime: fridayEndTime,
            saturdayStartTime: saturdayStartTime,
            saturdayEndTime: saturdayEndTime,
            sundayStartTime: sundayStartTime,
            sundayEndTime: sundayEndTime,
            isUnknownTarget: isUnknownTarget,
            isHikvision: isHikvision,
            isWatchOver: isWatchOver,
            isAutoUnlock: isAutoUnlock,
          })).then(result => {
            this.toastr.success('保存されました。', '成功');
            this.registerForm.reset();
            this.router.navigate(['/alertsettingmanagement'])
          }).catch(error => {
            this.toastr.error(error.message, 'エラー');
          });
      }
      setTimeout(() => this.spinner.hide(), 1500);
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }
  }

  get f() { return this.registerForm.controls; }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }
}
