<aside #mainSidebar class="main-sidebar sidebar-dark-primary elevation-4">
  <!-- Brand Logo -->
  <a [routerLink]="['/']" class="brand-link">
    <!--img src="assets/img/hitoterrace_logo.png" alt="HitoTerrace Logo"-->
    <img src="assets/img/hitoterrace_favicon.png" alt="Hitoterrace Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
    <!--img src="assets/img/HitoterraceLogo.png" alt="Hitoterrace Logo" class="brand-image img-circle elevation-3" style="opacity: .8"-->
    <span class="brand-text font-weight-light"><strong>Hito Terrace</strong></span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="info">
        <a [routerLink]="['/profile']" class="d-block">{{userName}}</a>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class with font-awesome or any other icon font library -->
        
        <!-- <li class="nav-item">
          <a [routerLink]="['/']" class="nav-link">
            <em class="nav-icon fas fa-tachometer-alt"></em>
            <p>
              ダッシュボード
            </p>
          </a>
        </li> -->

        <!-- <li *ngIf="systemAdmin" class="nav-item">
          <a [routerLink]="['/alertmessagesystemadminlist']" class="nav-link">
            <em class="nav-icon fas fa-list"></em>
            <p>
              アラート履歴（システム全体）
            </p>
          </a>
        </li> -->
        <li *ngIf="tenantAdmin" class="nav-item">
          <a [routerLink]="['/alertmessagetenantadminlist']" class="nav-link">
            <em class="nav-icon fas fa-list"></em>
            <p>
              アラート履歴（全体）
            </p>
          </a>
        </li>
        <!-- <li *ngIf="tenantUser" class="nav-item">
          <a [routerLink]="['/alertmessagetenantuserlist']" class="nav-link">
            <em class="nav-icon fas fa-list"></em>
            <p>
              アラート履歴（個人）
            </p>
          </a>
        </li> -->
        <li *ngIf="tenantAdmin" class="nav-item">
          <a [routerLink]="['/targetpersonmanagement']" class="nav-link">
            <em class="nav-icon fas fa-users"></em>
            <p>
              対象者管理
            </p>
          </a>
        </li>
<!--        
        <li *ngIf="tenantAdmin" class="header">テナント</li>
-->        
        <li *ngIf="tenantAdmin" class="nav-item">
          <a [routerLink]="['/accountmanagement']" class="nav-link">
            <em class="nav-icon fas fa-user"></em>
            <p>
              職員管理
            </p>
          </a>
        </li>
        <li *ngIf="tenantAdmin" class="nav-item">
          <a [routerLink]="['/alertsettingmanagement']" class="nav-link">
            <em class="nav-icon fas fa-exclamation-triangle"></em>
            <p>
              アラート設定
            </p>
          </a>
        </li>
        <li *ngIf="tenantAdmin" class="nav-item">
          <a [routerLink]="['/tenantsetting']" class="nav-link">
            <em class="nav-icon fas fa-cog"></em>
            <p>
              テナント設定
            </p>
          </a>
        </li>
        <li *ngIf="systemAdmin" class="header">システム</li>
        <li *ngIf="systemAdmin" class="nav-item">
          <a [routerLink]="['/tenantmanagement']" class="nav-link">
            <em class="nav-icon fas fa-hospital-o"></em>
            <p>
              テナント管理
            </p>
          </a>
        </li>
        <li *ngIf="systemAdmin" class="header">TEST TOOL</li>
        <li *ngIf="systemAdmin" class="nav-item">
          <a [routerLink]="['/testalertmessage']" class="nav-link">
            <em class="nav-icon fas fa-hand-peace-o"></em>
            <p>
              Test Alert Message
            </p>
          </a>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>