import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { AmplifyService } from 'aws-amplify-angular';

import { AppService } from '../../../../utils/services/app.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
          AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';

@Component({
  selector: 'app-systemadmindashboard',
  templateUrl: './systemadmindashboard.component.html',
  styleUrls: ['./systemadmindashboard.component.scss']
})
export class SystemAdminDashboardComponent implements OnInit {

  constructor(
    private auth: AuthService
  ) { }

  subscription: Subscription;
  tenantId: string = '';

  ngOnInit() {
    this.subscription = this.auth.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
      });
    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        // syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        // syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        // syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        // syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });
    DataStore.start();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
