import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { AmplifyService } from 'aws-amplify-angular';
import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AppService } from '../../../../utils/services/app.service';
import { Tenant, AlertMessage, AlertMessageStatus, AlertMessagePrivate,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';
import { isEmpty } from '@aws-amplify/core';

@Component({
  selector: 'app-createargetperson',
  templateUrl: './createtargetperson.component.html',
  styleUrls: ['./createtargetperson.component.scss']
})
export class CreateTargetPersonComponent implements OnInit {
  public registerForm: FormGroup;
  isValidFormSubmitted = false;
  image1FileToUpload: File = null;
  image2FileToUpload: File = null;
  image3FileToUpload: File = null;
  image4FileToUpload: File = null;
  image5FileToUpload: File = null;
  img1Src: string | ArrayBuffer = "";
  img2Src: string | ArrayBuffer = "";
  img3Src: string | ArrayBuffer = "";
  img4Src: string | ArrayBuffer = "";
  img5Src: string | ArrayBuffer = "";
  prefix = "targetperson/masterimages/";
  tenantId: string = "";
  subscription1;
  subscription2;
  alertSettings;
  image1FileType: string;
  image2FileType: string;
  image3FileType: string;
  image4FileType: string;
  image5FileType: string;
  image1FileSize: number;
  image2FileSize: number;
  image3FileSize: number;
  image4FileSize: number;
  image5FileSize: number;
  image1Width: number;
  image1Height: number;
  image2Width: number;
  image2Height: number;
  image3Width: number;
  image3Height: number;
  image4Width: number;
  image4Height: number;
  image5Width: number;
  image5Height: number;
  imageMinWidth: number = 80;
  imageMinHeight: number = 80;
  imageMaxWidth: number = 1920;
  imageMaxHeight: number = 1080;
  isImage1MinSize: boolean;
  isImage1MaxSize: boolean;
  isImage2MinSize: boolean;
  isImage2MaxSize: boolean;
  isImage3MinSize: boolean;
  isImage3MaxSize: boolean;
  isImage4MinSize: boolean;
  isImage4MaxSize: boolean;
  isImage5MinSize: boolean;
  isImage5MaxSize: boolean;
  isImageRequired: boolean;
  isImageSize:boolean;
  isImageType:boolean;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private amplifyService: AmplifyService,
    private appService: AppService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.subscription1 = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
        this.listAlertSettingMaster();
      });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });

    this.subscription2 = DataStore.observe(AlertSettingMaster).subscribe(msg => {
      this.listAlertSettingMaster();
    });
    this.registerForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(64), Validators.pattern('[^",]+')]],
      uid: ['', [Validators.required, Validators.maxLength(40), Validators.pattern('[^",]+')]],
      description: ['', [Validators.required, Validators.maxLength(300), Validators.pattern('[^",]+')]],
      roomNumber: ['', [Validators.required, Validators.maxLength(8), Validators.pattern('[^",]+')]],
      enable: [false],
      unlockAreaA: [false],
      unlockAreaB: [false],
      unlockAreaC: [false],
      unlockAreaD: [false],
      unlockAreaE: [false],
      alertSettingMasterId: ['', [Validators.required]],
    });
  }

  listAlertSettingMaster() {
    let alertSettingssub = from(DataStore.query(AlertSettingMaster, c => c.tenantId("eq", this.tenantId)));
    alertSettingssub.subscribe(resp => {
      this.alertSettings = resp;
    })
  }

  handleimage1FileInput(evt) {
    var files = evt.target.files;
    this.image1FileType = "";
    this.isImage1MinSize = true;
    this.isImage1MaxSize = true;
    this.image1FileSize = files[0].size;

    // fileが選択されていなければリセット
    if (files.length === 0) {
      this.image1FileToUpload = null;
      this.img1Src = '';
      return;
    }

    for (var i = 0; i < files.length; i++) {
      // ファイルの情報をimage1FileToUploadとimg1Srcに保存
      let reader = new FileReader();
      let fileType = "unknown";
      reader.onload = (evt) => {
        fileType = this.getImageFileType(evt.target.result);
        //alert('FileType: ' + fileType);

        if(fileType === "JPG"){
          this.image1FileType = "JPG";
          this.image1FileToUpload = files.item(0);
          reader.onload = (evt) => {
            this.img1Src = reader.result;
            var image = new Image();

            image.onload = ()=>{
              this.image1Width = image.width;
              this.image1Height = image.height;
              // console.log('width_1: ' + this.image1Width);
              // console.log('height_1: ' + this.image1Height);

              if ( (this.image1Width < this.imageMinWidth) || (this.image1Height < this.imageMinHeight) ) {
                this.isImage1MinSize = false;
              }

              if ( (this.imageMaxWidth < this.image1Width) || (this.imageMaxHeight < this.image1Height) ) {
                this.isImage1MaxSize = false;
              }

              // console.log('isImage1MinSize: ' + this.isImage1MinSize);
              // console.log('isImage1MaxSize: ' + this.isImage1MaxSize);
            };
            image.src = this.img1Src.toString();
          }
          reader.readAsDataURL(this.image1FileToUpload);
        }else{
          this.image1FileType = "unknown";
          this.img1Src = '';
        }
      }
      reader.readAsArrayBuffer(files[i]);
    }
  }

  handleimage2FileInput(evt) {
    var files = evt.target.files;
    this.image2FileType = "";
    this.isImage2MinSize = true;
    this.isImage2MaxSize = true;
    this.image2FileSize = files[0].size;

    if (files.length === 0) {
      this.image2FileToUpload = null;
      this.img2Src = '';
      return;
    }

    for (var i = 0; i < files.length; i++) {
      // ファイルの情報をimage2FileToUploadとimg2Srcに保存
      let reader = new FileReader();
      let fileType = "unknown";
      reader.onload = (evt) => {
        fileType = this.getImageFileType(evt.target.result);

        if(fileType === "JPG"){
          this.image2FileType = "JPG";
          this.image2FileToUpload = files.item(0);
          reader.onload = (evt) => {
            this.img2Src = reader.result;
            var image = new Image();

            image.onload = ()=>{
              this.image2Width = image.width;
              this.image2Height = image.height;
              // console.log('width_2: ' + this.image2Width);
              // console.log('height_2: ' + this.image2Height);

              if ( (this.image2Width < this.imageMinWidth) || (this.image2Height < this.imageMinHeight) ) {
                this.isImage2MinSize = false;
              }

              if ( (this.imageMaxWidth < this.image2Width) || (this.imageMaxHeight < this.image2Height) ) {
                this.isImage2MaxSize = false;
              }

              // console.log('isImage2MinSize: ' + this.isImage2MinSize);
              // console.log('isImage2MaxSize: ' + this.isImage2MaxSize);
            };
            image.src = this.img2Src.toString();
          }
          reader.readAsDataURL(this.image2FileToUpload);
        }else{
          this.image2FileType = "unknown";
          this.img2Src = '';
        }
      }
      reader.readAsArrayBuffer(files[i]);
    }
  }

  handleimage3FileInput(evt) {
    var files = evt.target.files;
    this.image3FileType = "";
    this.isImage3MinSize = true;
    this.isImage3MaxSize = true;
    this.image3FileSize = files[0].size;

    if (files.length === 0) {
      this.image3FileToUpload = null;
      this.img3Src = '';
      return;
    }

    for (var i = 0; i < files.length; i++) {
      // ファイルの情報をimage3FileToUploadとimg3Srcに保存
      let reader = new FileReader();
      let fileType = "unknown";
      reader.onload = (evt) => {
        fileType = this.getImageFileType(evt.target.result);

        if(fileType === "JPG"){
          this.image3FileType = "JPG";
          this.image3FileToUpload = files.item(0);
          reader.onload = (evt) => {
            this.img3Src = reader.result;
            var image = new Image();

            image.onload = ()=>{
              this.image3Width = image.width;
              this.image3Height = image.height;
              // console.log('width_3: ' + this.image3Width);
              // console.log('height_3: ' + this.image3Height);

              if ( (this.image3Width < this.imageMinWidth) || (this.image3Height < this.imageMinHeight) ) {
                this.isImage3MinSize = false;
              }

              if ( (this.imageMaxWidth < this.image3Width) || (this.imageMaxHeight < this.image3Height) ) {
                this.isImage3MaxSize = false;
              }

              // console.log('isImage3MinSize: ' + this.isImage3MinSize);
              // console.log('isImage3MaxSize: ' + this.isImage3MaxSize);
            };
            image.src = this.img3Src.toString();
          }
          reader.readAsDataURL(this.image3FileToUpload);
        }else{
          this.image3FileType = "unknown";
          this.img3Src = '';
        }
      }
      reader.readAsArrayBuffer(files[i]);
    }
  }

  handleimage4FileInput(evt) {
    var files = evt.target.files;
    this.image4FileType = "";
    this.isImage4MinSize = true;
    this.isImage4MaxSize = true;
    this.image4FileSize = files[0].size;

    if (files.length === 0) {
      this.image4FileToUpload = null;
      this.img4Src = '';
      return;
    }

    for (var i = 0; i < files.length; i++) {
      // ファイルの情報をimage4FileToUploadとimg4Srcに保存
      let reader = new FileReader();
      let fileType = "unknown";
      reader.onload = (evt) => {
        fileType = this.getImageFileType(evt.target.result);

        if(fileType === "JPG"){
          this.image4FileType = "JPG";
          this.image4FileToUpload = files.item(0);
          reader.onload = (evt) => {
            this.img4Src = reader.result;
            var image = new Image();

            image.onload = ()=>{
              this.image4Width = image.width;
              this.image4Height = image.height;
              // console.log('width_4: ' + this.image4Width);
              // console.log('height_4: ' + this.image4Height);

              if ( (this.image4Width < this.imageMinWidth) || (this.image4Height < this.imageMinHeight) ) {
                this.isImage4MinSize = false;
              }

              if ( (this.imageMaxWidth < this.image4Width) || (this.imageMaxHeight < this.image4Height) ) {
                this.isImage4MaxSize = false;
              }

              // console.log('isImage4MinSize: ' + this.isImage4MinSize);
              // console.log('isImage4MaxSize: ' + this.isImage4MaxSize);
            };
            image.src = this.img4Src.toString();
          }
          reader.readAsDataURL(this.image4FileToUpload);
        }else{
          this.image4FileType = "unknown";
          this.img4Src = '';
        }
      }
      reader.readAsArrayBuffer(files[i]);
    }
  }

  handleimage5FileInput(evt) {
    var files = evt.target.files;
    this.image5FileType = "";
    this.isImage5MinSize = true;
    this.isImage5MaxSize = true;
    this.image5FileSize = files[0].size;

    if (files.length === 0) {
      this.image5FileToUpload = null;
      this.img5Src = '';
      return;
    }

    for (var i = 0; i < files.length; i++) {
      // ファイルの情報をimage5FileToUploadとimg5Srcに保存
      let reader = new FileReader();
      let fileType = "unknown";
      reader.onload = (evt) => {
        fileType = this.getImageFileType(evt.target.result);

        if(fileType === "JPG"){
          this.image5FileType = "JPG";
          this.image5FileToUpload = files.item(0);
          reader.onload = (evt) => {
            this.img5Src = reader.result;
            var image = new Image();

            image.onload = ()=>{
              this.image5Width = image.width;
              this.image5Height = image.height;
              // console.log('width_5: ' + this.image5Width);
              // console.log('height_5: ' + this.image5Height);

              if ( (this.image5Width < this.imageMinWidth) || (this.image5Height < this.imageMinHeight) ) {
                this.isImage5MinSize = false;
              }

              if ( (this.imageMaxWidth < this.image5Width) || (this.imageMaxHeight < this.image5Height) ) {
                this.isImage5MaxSize = false;
              }

              // console.log('isImage5MinSize: ' + this.isImage5MinSize);
              // console.log('isImage5MaxSize: ' + this.isImage5MaxSize);
            };
            image.src = this.img5Src.toString();
          }
          reader.readAsDataURL(this.image5FileToUpload);
        }else{
          this.image5FileType = "unknown";
          this.img5Src = '';
        }
      }
      reader.readAsArrayBuffer(files[i]);
    }
  }

  private getAlertSetting(id: string) {
    return from(DataStore.query(AlertSettingMaster, id));
  }

  onClickCreateTargetPerson() {
    this.isValidFormSubmitted = true;

//  let fileType = "image/jpeg";
    let fileType = /image\/\w*/;

    if (this.image1FileToUpload !== null) {
      if (this.image1FileToUpload.type.match(fileType) === null) this.toastr.error('マスター画像 1 のファイルタイプが違います。', 'エラー');
    }
    if (this.image2FileToUpload !== null) {
      if (this.image2FileToUpload.type.match(fileType) === null) this.toastr.error('マスター画像 2 のファイルタイプが違います。', 'エラー');
    }
    if (this.image3FileToUpload !== null) {
      if (this.image3FileToUpload.type.match(fileType) === null) this.toastr.error('マスター画像 3 のファイルタイプが違います。', 'エラー');
    }
    if (this.image4FileToUpload !== null) {
      if (this.image4FileToUpload.type.match(fileType) === null) this.toastr.error('マスター画像 4 のファイルタイプが違います。', 'エラー');
    }
    if (this.image5FileToUpload !== null) {
      if (this.image5FileToUpload.type.match(fileType) === null) this.toastr.error('マスター画像 5 のファイルタイプが違います。', 'エラー');
    }

    if (this.image1FileType === "unknown") {
      this.toastr.error('マスター画像 1 のファイルタイプが違います。', 'エラー');
    }
    if (this.image2FileType === "unknown") {
      this.toastr.error('マスター画像 2 のファイルタイプが違います。', 'エラー');
    }
    if (this.image3FileType === "unknown") {
      this.toastr.error('マスター画像 3 のファイルタイプが違います。', 'エラー');
    }
    if (this.image4FileType === "unknown") {
      this.toastr.error('マスター画像 4 のファイルタイプが違います。', 'エラー');
    }
    if (this.image5FileType === "unknown") {
      this.toastr.error('マスター画像 5 のファイルタイプが違います。', 'エラー');
    }

    if (this.isImage1MinSize === false) {
      this.toastr.error('マスター画像 1 の画像が小さいです。', 'エラー');
    }
    if (this.isImage2MinSize === false) {
      this.toastr.error('マスター画像 2 の画像が小さいです。', 'エラー');
    }
    if (this.isImage3MinSize === false) {
      this.toastr.error('マスター画像 3 の画像が小さいです。', 'エラー');
    }
    if (this.isImage4MinSize === false) {
      this.toastr.error('マスター画像 4 の画像が小さいです。', 'エラー');
    }
    if (this.isImage5MinSize === false) {
      this.toastr.error('マスター画像 5 の画像が小さいです。', 'エラー');
    }

    if (this.isImage1MaxSize === false) {
      this.toastr.error('マスター画像 1 の画像が大きいです。', 'エラー');
    }
    if (this.isImage2MaxSize === false) {
      this.toastr.error('マスター画像 2 の画像が大きいです。', 'エラー');
    }
    if (this.isImage3MaxSize === false) {
      this.toastr.error('マスター画像 3 の画像が大きいです。', 'エラー');
    }
    if (this.isImage4MaxSize === false) {
      this.toastr.error('マスター画像 4 の画像が大きいです。', 'エラー');
    }
    if (this.isImage5MaxSize === false) {
      this.toastr.error('マスター画像 5 の画像が大きいです。', 'エラー');
    }

    if(
      ((this.image1FileType !== 'JPG')&&(this.image2FileType !== 'JPG')&&(this.image3FileType !== 'JPG')&&
        (this.image4FileType !== 'JPG')&&(this.image5FileType !== 'JPG'))
      ||((this.image1FileToUpload === null)&&(this.image2FileToUpload === null)&&(this.image3FileToUpload === null)&&
        (this.image4FileToUpload === null)&&(this.image5FileToUpload === null))
    ){
      if(
        (this.image1FileType !== 'unknown')||(this.image2FileType !== 'unknown')||(this.image3FileType !== 'unknown')||
        (this.image4FileType !== 'unknown')||(this.image5FileType !== 'unknown')
      ){
        this.toastr.error('画像が一つも登録されていません。', 'エラー');
      }
    }
    if (this.registerForm.valid) {
      this.spinner.show();
      let name = this.registerForm.get("name").value;
      let uid = this.registerForm.get("uid").value;
      let description = this.registerForm.get("description").value;
      let roomNumber = this.registerForm.get("roomNumber").value;
      let alertSettingMasterId = this.registerForm.get("alertSettingMasterId").value;
      let enable = false;
      if (this.registerForm.get("enable").value == true) {
        enable = true;
      }
      let unlockAreaA = false;
      if (this.registerForm.get("unlockAreaA").value == true) {
        unlockAreaA = true;
      }
      let unlockAreaB = false;
      if (this.registerForm.get("unlockAreaB").value == true) {
        unlockAreaB = true;
      }
      let unlockAreaC = false;
      if (this.registerForm.get("unlockAreaC").value == true) {
        unlockAreaC = true;
      }
      let unlockAreaD = false;
      if (this.registerForm.get("unlockAreaD").value == true) {
        unlockAreaD = true;
      }
      let unlockAreaE = false;
      if (this.registerForm.get("unlockAreaE").value == true) {
        unlockAreaE = true;
      }
      let unlockArea = "";
      let unlockAreaArray = [];
      if (unlockAreaA) {
        unlockAreaArray.push("A");
      }
      if (unlockAreaB) {
        unlockAreaArray.push("B");
      }
      if (unlockAreaC) {
        unlockAreaArray.push("C");
      }
      if (unlockAreaD) {
        unlockAreaArray.push("D");
      }
      if (unlockAreaE) {
        unlockAreaArray.push("E");
      }
      unlockArea = unlockAreaArray.join(",");
      const uuidKey = uuid();
      let image1FileKey = "";
      let image2FileKey = "";
      let image3FileKey = "";
      let image4FileKey = "";
      let image5FileKey = "";
      if ((this.image1FileToUpload !== null)&&(this.image1FileType === "JPG")) {
        image1FileKey = this.prefix + uuidKey + '/' + uuid() + '.jpg';
        this.amplifyService.storage().put(image1FileKey, this.image1FileToUpload, { level: 'public' })
          // .then(result => console.log(result))
          // .catch(err => console.log(err));
      }
      if ((this.image2FileToUpload !== null)&&(this.image2FileType === "JPG")) {
        image2FileKey = this.prefix + uuidKey + '/' + uuid() + '.jpg';
        this.amplifyService.storage().put(image2FileKey, this.image2FileToUpload, { level: 'public' })
          // .then(result => console.log(result))
          // .catch(err => console.log(err));
      }
      if ((this.image3FileToUpload !== null)&&(this.image3FileType === "JPG")) {
        image3FileKey = this.prefix + uuidKey + '/' + uuid() + '.jpg';
        this.amplifyService.storage().put(image3FileKey, this.image3FileToUpload, { level: 'public' })
          // .then(result => console.log(result))
          // .catch(err => console.log(err));
      }
      if ((this.image4FileToUpload !== null)&&(this.image4FileType === "JPG")) {
        image4FileKey = this.prefix + uuidKey + '/' + uuid() + '.jpg';
        this.amplifyService.storage().put(image4FileKey, this.image4FileToUpload, { level: 'public' })
          // .then(result => console.log(result))
          // .catch(err => console.log(err));
      }
      if ((this.image5FileToUpload !== null)&&(this.image5FileType === "JPG")) {
        image5FileKey = this.prefix + uuidKey + '/' + uuid() + '.jpg';
        this.amplifyService.storage().put(image5FileKey, this.image5FileToUpload, { level: 'public' })
          // .then(result => console.log(result))
          // .catch(err => console.log(err));
      }
      if(
          ((this.image1FileType === "JPG")||(this.image2FileType === "JPG")||
            (this.image3FileType === "JPG")||(this.image4FileType === "JPG")||(this.image5FileType === "JPG"))
          &&((this.image1FileType !== "unknown")&&(this.image2FileType !== "unknown")&&
            (this.image3FileType !== "unknown")&&(this.image4FileType !== "unknown")&&(this.image5FileType !== "unknown"))
          &&((this.isImage1MinSize !== false)&&(this.isImage1MaxSize !== false))
          &&((this.isImage2MinSize !== false)&&(this.isImage2MaxSize !== false))
          &&((this.isImage3MinSize !== false)&&(this.isImage3MaxSize !== false))
          &&((this.isImage4MinSize !== false)&&(this.isImage4MaxSize !== false))
          &&((this.isImage5MinSize !== false)&&(this.isImage5MaxSize !== false))
        ){
          this.getAlertSetting(alertSettingMasterId).subscribe(item => {
            DataStore.save(
              new TargetPersonMaster({
                name: name,
                uid: uid,
                tenantId: this.tenantId,
                description: description,
                roomNumber: roomNumber,
                enable: enable,
                unlockArea: unlockArea,
                unlockAreaA: unlockAreaA,
                unlockAreaB: unlockAreaB,
                unlockAreaC: unlockAreaC,
                unlockAreaD: unlockAreaD,
                unlockAreaE: unlockAreaE,
                imege1Key: image1FileKey,
                imege2Key: image2FileKey,
                imege3Key: image3FileKey,
                imege4Key: image4FileKey,
                imege5Key: image5FileKey,
                alertSettingMaster: item,
              })).then(result => {
                this.toastr.success('保存されました。', '成功');
                setTimeout(() => {
                  this.registerForm.reset();
                  this.router.navigate(['/targetpersonmanagement'])
                  }, 500);
              }).catch(error => {
                this.toastr.error(error.message, 'エラー');
              });
          });
        }

      setTimeout(() => this.spinner.hide(), 500);
/*
    } else if (this.image1FileToUpload.type != fileType || this.image2FileToUpload.type != fileType || this.image3FileToUpload.type != fileType || this.image4FileToUpload.type != fileType || this.image5FileToUpload.type != fileType) {
      this.toastr.error('ファイルタイプが違います。', 'エラー');
*/
    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }
  }

  get f() { return this.registerForm.controls; }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }

  // イメージのファイルタイプをバイナリーレベルで調べる
  getImageFileType(arrayBuffer) {
    let fileType = "unknown";
    let headerStr = "";
    let headerHex = "";
    let ba = new Uint8Array(arrayBuffer);
    let baValue = undefined;
    // console.log('BA ' + ba);

    if ( isEmpty(ba) ) {
      fileType = "unknown";
      headerStr = "";
      headerHex = "";
    } else {
      for (let i = 0; i < 10; i++) { // 始めの10個分を読む
        // console.log('文字', ba[i])
        baValue = ba[i];

        if ( baValue === undefined || baValue === null ) {
          fileType = "unknown";
          headerStr = "";
          headerHex = "";
          break;
        } else {
          headerHex += ba[i].toString(16); // 16進文字列で読む
          // headerStr += String.fromCharCode(ba[i]); // 文字列で読む
        }
      }

      if (headerHex.indexOf("ffd8") != -1) { // JPGはヘッダーに「ffd8」を含む
        fileType = "JPG";
      } else if (headerHex.indexOf("8950") != -1) { // PNGはヘッダーに「8950」を含む
        fileType = "PNG";
      } else if (headerHex.indexOf("4749") != -1) { // GIFはヘッダーに「4749」を含む
        fileType = "GIF";
      } else if (headerHex.indexOf("424d") != -1) { // BMPはヘッダーに「424d」を含む
        fileType = "BMP";
      } else {
        fileType = "OTHERS";
      }
    }
    // console.log("fileType=" + fileType + " headerStr=" + headerStr + " headerHex=" + headerHex);
    return fileType;
  }

}
