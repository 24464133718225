import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { v4 as uuid } from 'uuid';
import { Subscription } from 'rxjs/Subscription';

import { DataStore, Predicates } from '@aws-amplify/datastore';
import { AmplifyService } from 'aws-amplify-angular';
import { AlertMessage, AlertMessageStatus, AlertMessagePrivate } from '../../../../models';
import { AppService } from '../../../utils/services/app.service';


@Component({
  selector: 'app-testalertmessage',
  templateUrl: './testalertmessage.component.html',
  styleUrls: ['./testalertmessage.component.scss']
})
export class TestAlertMessageComponent implements OnInit {
  public registerForm: FormGroup;
  isValidFormSubmitted = null;
  image1FileToUpload: File = null;
  image2FileToUpload: File = null;
  image3FileToUpload: File = null;
  prefix = "alertmessage/";
  tenantId: string = "";
  subscription: Subscription;


  constructor(
    private router: Router,
    private toastr: ToastrService,
    private amplifyService: AmplifyService,
    private appService: AppService,
  ) { }

  ngOnInit() {
    this.subscription = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
      });
    this.registerForm = new FormGroup({
      alertKind: new FormControl("HighFever", [Validators.required]),
      name: new FormControl("山田　花子", [Validators.required]),
      uid: new FormControl("01010", [Validators.required]),
      content: new FormControl("38.6℃です。", [Validators.required]),
      detail: new FormControl("38.6℃", [Validators.required]),
      cameraId: new FormControl("111", [Validators.required]),
      cameraDescription: new FormControl("カメラの詳細情報", [Validators.required]),
      cameraThreshold: new FormControl("80", [Validators.required]),
    });
  }

  get alertKind() {
    return this.registerForm.get('alertKind');
  }

  get name() {
    return this.registerForm.get('name');
  }

  get uid() {
    return this.registerForm.get('uid');
  }

  get content() {
    return this.registerForm.get('content');
  }

  get detail() {
    return this.registerForm.get('detail');
  }

  get cameraId() {
    return this.registerForm.get('cameraId');
  }

  get cameraDescription() {
    return this.registerForm.get('cameraDescription');
  }

  get cameraThreshold() {
    return this.registerForm.get('cameraThreshold');
  }

  get imege1Key() {
    return this.registerForm.get('imege1Key');
  }

  get imege2Key() {
    return this.registerForm.get('imege2Key');
  }

  get imege3Key() {
    return this.registerForm.get('imege3Key');
  }

  handleimage1FileInput(files: FileList) {
    this.image1FileToUpload = files.item(0);
  }

  handleimage2FileInput(files: FileList) {
    this.image2FileToUpload = files.item(0);
  }

  handleimage3FileInput(files: FileList) {
    this.image3FileToUpload = files.item(0);
  }

  onClickDeleteAllAlertMessage() {
    DataStore.delete(
      AlertMessage,
      Predicates.ALL,
    );
    DataStore.delete(
      AlertMessageStatus,
      Predicates.ALL,
    );
  }

  onClickCreateAlertMessage() {
    this.isValidFormSubmitted = false;
    if (this.registerForm.valid) {
      this.isValidFormSubmitted = true;
      let alertKind = this.registerForm.get("alertKind").value;
      let name = this.registerForm.get("name").value;
      let uid = this.registerForm.get("uid").value;
      let content = this.registerForm.get("content").value;
      let detail = this.registerForm.get("detail").value;
      let cameraId = this.registerForm.get("cameraId").value;
      let cameraDescription = this.registerForm.get("cameraDescription").value;
      let cameraThreshold = this.registerForm.get("cameraThreshold").value;

      const uuidKey = uuid();
      let image1FileKey = "";
      let image2FileKey = "";
      let image3FileKey = "";
      if (this.image1FileToUpload != null) {
        image1FileKey = this.prefix + uuidKey + '/' + uuid() + '.jpg';
        this.amplifyService.storage().put(image1FileKey, this.image1FileToUpload, { level: 'public' })
          .then(result => console.log(result))
          .catch(err => console.log(err));
      }
      if (this.image2FileToUpload != null) {
        image2FileKey = this.prefix + uuidKey + '/' + uuid() + '.jpg';
        this.amplifyService.storage().put(image2FileKey, this.image2FileToUpload, { level: 'public' })
          .then(result => console.log(result))
          .catch(err => console.log(err));
      }
      if (this.image3FileToUpload != null) {
        image3FileKey = this.prefix + uuidKey + '/' + uuid() + '.jpg';
        this.amplifyService.storage().put(image3FileKey, this.image3FileToUpload, { level: 'public' })
          .then(result => console.log(result))
          .catch(err => console.log(err));
      }
      var unixtime = new Date().getTime() / 1000;
      var today = new Date();
      DataStore.save(new AlertMessageStatus({
        alertStatus: "未対処"
      })).then(status => {
        DataStore.save(
          new AlertMessage({
            alertKind: alertKind,
            name: name,
            uid: uid,
            tenantId: this.tenantId,
            content: content,
            detail: detail,
            cameraId: cameraId,
            cameraDescription: cameraDescription,
            cameraThreshold: cameraThreshold,
            shootingDataTime: JSON.stringify(
              today.getFullYear() + '-' + (today.getMonth()+1) + '-'+ today.getDate()),
            imege1Key: image1FileKey,
            imege2Key: image2FileKey,
            imege3Key: image3FileKey,
            thumbnail1Key: image1FileKey,
            thumbnail2Key: image2FileKey,
            thumbnail3Key: image3FileKey,
            alertMessageStatus: status,
            createDateTime: Math.floor(unixtime),
          })
        ).then(alertmessage => {
          DataStore.save(new AlertMessagePrivate({
            alreadyRead: false,
            alertMessage: alertmessage,
            createDateTime: Math.floor(unixtime),
          }));
        });

      });
      this.registerForm.reset();
      this.router.navigate(['/alertmessagelist'])
    } else {
      this.toastr.error('Hello world!', 'Toastr fun!');
    }

  }


  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
