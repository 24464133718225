<!-- Content Header (Page header) -->
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <!--h1>TenantUser Dashboard</h1-->
      </div>
    </div>
  </div>
</section>

<!-- Main content -->
<section class="content">
  <div class="container-fluid">

    <h2>{{tenantId}}</h2>
    <h3>ユーザー</h3>

    <!-- <div class="card">
      <div class="card-header">
        <h3 class="card-title">Alert Message</h3>
      </div>
      <div class="card-body">
        <div *ngIf="alertMessage != null">
          <div>
            <span>{{alertMessage.name}}</span>
          </div>
          <div>
            <span>{{alertMessage.alertKind}}</span>
          </div>
          <div>
            <span>{{alertMessage.cameraId}}</span>
          </div>
          <div>
            <span>{{alertMessage.cameraThreshold}}</span>
          </div>
          <div>
            <span>{{alertMessage.cameraDescription}}</span>
          </div>
          <div>
            <span>{{alertMessage.content}}</span>
          </div>
          <div>
            <span>{{alertMessage.detail}}</span>
          </div>
          <div>
            <img [src]="thumbnail1Url">
          </div>
          <div>
            <img [src]="thumbnail2Url">
          </div>
          <div>
            <img [src]="thumbnail3Url">
          </div>
        </div>
      </div>
    </div> -->

  </div>
</section>