import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { AuthService } from '../../../utils/services/auth.service';
import { MustMatch } from '../../../utils/must-match.validator';
import { resourceUsage } from 'process';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public registerForm: FormGroup;
  isValidFormSubmitted = null;
  invalidPasswordException: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private auth: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
  }


  onClickEditPassword() {
    this.isValidFormSubmitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();
      const oldPassword = this.registerForm.get("oldPassword").value;
      const newPassword = this.registerForm.get("newPassword").value;
      this.auth.getCurrentAuthenticatedUser().subscribe(user => {
        this.auth.changePassword(user, oldPassword, newPassword)
          .subscribe(
            result => {
              setTimeout(() => this.spinner.hide(), 500);
              this.toastr.success('保存されました。', '成功');
            },
            error => {
              setTimeout(() => this.spinner.hide(), 500);
              // console.log(error);
              if (error.code == "InvalidPasswordException") {
                this.invalidPasswordException = true;
                this.toastr.error('パスワードの要件を満たしていません。', 'エラー');
              } else if (error.code == "NotAuthorizedException") {
                this.toastr.error('パスワードが間違えています。', 'エラー');
              } else if (error.code = "LimitExceededException") {
                this.toastr.error('連続してパスワード変更要求がありました。しばらくしてから再度実行してください。', 'エラー');
              } else {
                this.toastr.error('パスワード変更に失敗しました。', 'エラー');
              }
            });
      },
        error => {
          setTimeout(() => this.spinner.hide(), 500);
          this.toastr.error('パスワード変更に失敗しました。', 'エラー');
        });

    } else {
      this.toastr.error('必要な情報が入力されていません。', 'エラー');
    }
  }

  get f() { return this.registerForm.controls; }

}
