import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';

import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { AmplifyService } from 'aws-amplify-angular';

import { AppService } from '../../../../utils/services/app.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Tenant, AlertMessage, AlertMessagePrivate, AlertMessageStatus,
         AccountMaster, TargetPersonMaster, AlertSettingMaster } from '../../../../../models';


@Component({
  selector: 'app-tenantuserdashboard',
  templateUrl: './tenantuserdashboard.component.html',
  styleUrls: ['./tenantuserdashboard.component.scss']
})
export class TenantUserDashboardComponent implements OnInit {
  subscription: Subscription;
//subscription1;
  subscription2;
  tenantId: string = '';
//tenantName: string = '';
  alertMessage = null;
  thumbnail1Url = '';
  thumbnail2Url = '';
  thumbnail3Url = '';

  constructor(
    private amplifyService: AmplifyService,
    private appService: AppService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.subscription = this.auth.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
      });

    DataStore.configure({
      maxRecordsToSync: 1000000,
      syncExpressions: [
        syncExpression(Tenant, tenant                           => tenant.id('eq', this.tenantId)),
        syncExpression(AlertMessage, alertMessage               => alertMessage.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessagePrivate, alertMessagePrivate => alertMessagePrivate.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AlertMessageStatus, alertMessageStatus   => alertMessageStatus.tenantId('eq', this.tenantId).itemStatus('ne', 'deleted')),
        syncExpression(AccountMaster, accountMaster             => accountMaster.tenantId('eq', this.tenantId)),
        syncExpression(TargetPersonMaster, targetPersonMaster   => targetPersonMaster.tenantId('eq', this.tenantId)),
        syncExpression(AlertSettingMaster, alertSettingMaster   => alertSettingMaster.tenantId('eq', this.tenantId)),
      ]
    });
    DataStore.start();
/*
    this.subscription1 = this.appService.getTenantId()
      .subscribe(result => {
        this.tenantId = result;
      });
*/
    this.subscription2 = DataStore.observe(AlertMessagePrivate).subscribe(msg => {
      this.get(msg.element.id).subscribe(alertMessagePrivate => {
        // console.log(alertMessagePrivate);
        this.alertMessage = alertMessagePrivate.alertMessage;
        if (this.alertMessage.thumbnail1Key != '') {
          this.amplifyService.storage().get(this.alertMessage.thumbnail1Key, { level: 'public' }).then(url => {
            this.thumbnail1Url = url;
          });
        }
        else {
          this.thumbnail1Url = '';
        }
        if (this.alertMessage.thumbnail2Key != '') {
          this.amplifyService.storage().get(this.alertMessage.thumbnail2Key, { level: 'public' }).then(url => {
            this.thumbnail2Url = url;
          });
        }
        else {
          this.thumbnail2Url = '';
        }
        if (this.alertMessage.thumbnail3Key != '') {
          this.amplifyService.storage().get(this.alertMessage.thumbnail3Key, { level: 'public' }).then(url => {
            this.thumbnail3Url = url;
          });
        }
        else {
          this.thumbnail3Url = '';
        }
  
      });
    });
  }

  private get(id: string) {
    return from(DataStore.query(AlertMessagePrivate, id));
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
//  this.subscription1.unsubscribe();
  }
}
