import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ProfileRoutingModule } from './profile-routing.module';

import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AuthService } from '../../../utils/services/auth.service';

import { ProfileComponent } from './profile.component';

@NgModule({
  declarations: [
    ProfileComponent,
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    AmplifyAngularModule,
    DataTablesModule,
  ],
  providers: [AuthService, AmplifyService],
})
export class ProfileModule { }
